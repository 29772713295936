import { Component,Inject,Input,OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Urlbase } from 'app/config/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-caisse',
  templateUrl: './caisse.component.html',
  styleUrls: ['./caisse.component.scss']
})
export class CaisseComponent implements OnInit {

  title = 'caissevillage';
  menu_clic: any=1;
  data_menu : any=[{'id_choix':1,'nom_menu':'PERSONNE','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE'},{'id_choix':3,'nom_menu':'SOUSCRIPTION EAU'},{'id_choix':4,'nom_menu':'DECLARER DECES'}]
  nom_ut : any="";
  prenom_ut : any="";
  base_url : any=Urlbase;
  data_personne: any=[];
  data_famille : any=[];
  total_caisse : any=0;
  id_pers : any=0;
  data_mouvements : any=[];
  numero_mvt_v: any="";
  date_du_jour : any;
  data_recu : any=[];
  data :any=[];
  id_ut : any=0;
  data_choix : any=[{'id_choix':1,'nom_choix':'EAU','icon':'../assets/eau.png'},{'id_choix':2,'nom_choix':'FONCIER','icon':'../assets/terrain.jfif'},{'id_choix':3,'nom_choix':'FUNERAIRE','icon':'../assets/cercueil.png'},{'id_choix':4,'nom_choix':'LOCATION SALLES','icon':'../assets/eau.png'}]
  constructor( private router : Router, private datePipe: DatePipe, private http : HttpClient,private modalService : NgbModal)
  {
    this.nom_ut= localStorage.getItem('nom_ut')
    this.prenom_ut= localStorage.getItem('prenom_ut')
    this.liste_personne();
    this.liste_famille();
    this.liste_mvmt();
    
    this.date_du_jour=new Date();
    
  }
  retour()
  {
    this.router.navigate(['choix'])
  }
  supprimer(numero_ticket,type_mvt,numero_mvt)
  {
    Swal.fire({
        title: 'Voulez vous vraiment supprimer cet enregistrement ' +numero_ticket +  '? cela va annuler le reçu automatiquement',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let postData = new FormData();
            //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut');
            postData.append('id_ut' ,this.id_ut );
            
                postData.append('numero_ticket' , numero_ticket);
          
                postData.append('type_mvt' , type_mvt);
                postData.append('numero_mvt' , numero_mvt);

           
                
               
                this.http.post(this.base_url+'/apicaissevillage/utilisateur/supprimer_mouvement.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Ligne supprimée avec succès !',
                        showConfirmButton: true,
                        timer: 1500
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        this.liste_personne();
                        this.liste_mvmt();
                        this.liste_famille();
                      })
                  
                  }
                  else
                  {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Erreur innatendue',
                       
                      })
                  }
                }
                  )

                
         
        } else if (result.isDenied) {
          
        }
      })

  }
  recu(numero)
  {
    this.http.get(this.base_url+'/apicaissevillage/utilisateur/mouvement_id.php?numero_ticket='+numero).subscribe(data=>{
  
        this.data_recu=data;
         
        const modalRef = this.modalService.open(modalTicket);
            
        modalRef.componentInstance.numero_ticket=this.data_recu[0]['numero_ticket'];
        modalRef.componentInstance.date_ticket=this.data_recu[0]['date_ticket'];
        modalRef.componentInstance.heure_ticket=this.data_recu[0]['heure_ticket'];
        modalRef.componentInstance.libelle_ticket=this.data_recu[0]['libelle_ticket'];
        modalRef.componentInstance.type_ticket=this.data_recu[0]['type_ticket'];
        modalRef.componentInstance.montant_a_payer=this.data_recu[0]['montant_a_payer'];
        modalRef.componentInstance.montant_verse=this.data_recu[0]['montant_verse'];
        modalRef.componentInstance.monnaie=this.data_recu[0]['monnaie'];
        modalRef.componentInstance.caissier=this.data_recu[0]['caissier'];
    
        
        modalRef.result.then(
            (result) => {
           
            
            },
            (reason) => {
               
               if(reason==undefined)
               {
              
               
               }
            }
          );
        
       
       
       
  
    })

  }
  
  verifier()
  {
    if (this.numero_mvt_v=="")
    {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else
    {
      const modalRef = this.modalService.open(VerifierModalC);
      //alert("ff"+this.numero_mvt_v)
      modalRef.componentInstance.numero_mvt = this.numero_mvt_v;
      modalRef.result.then(
          (result) => {
          // this.liste_personne();
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
            //   this.liste_personne();
             
             }
          }
        );
    }
  
  }

  verifier_ticket(numero)
  {
   
   
      const modalRef = this.modalService.open(VerifierModalC);
      //alert("ff"+this.numero_mvt_v)
      modalRef.componentInstance.numero_mvt = numero;
      modalRef.result.then(
          (result) => {
          // this.liste_personne();
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
            //   this.liste_personne();
             
             }
          }
        );
    
  
  }
  ngOnInit() {
    
  }
  liste_personne()
  {
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
  
      this.data_personne=data;
      this.data_menu =[{'id_choix':1,'nom_menu':'AJOUTER PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'AJOUTER FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'AJOUTER SOUSCRIP.EAU'},{'id_choix':4,'nom_menu':'DECLARER DECES'}]
 
     
    
      
     
     
     

  })
  }
  liste_mvmt()
  { 
    var date_du_jour=new Date();
   
    let date_mvt=this.datePipe.transform(date_du_jour,"dd/MM/yyyy");
   // alert(date_mvt);
    this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?date_mvt='+date_mvt+'&etat=1').subscribe(data=>{
  
      console.log(data);
      this.data_mouvements=data;
      this.total_caisse=0;
      for(let i=0;i<this.data_mouvements.length;i++)
      {
        this.total_caisse=parseInt(this.total_caisse) + parseInt(this.data_mouvements[i]['montant_a_verse_mvt']);
      }
      //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  liste_famille()
  {
    this.http.get(this.base_url+'/apicaissevillage/famille/liste_famille.php').subscribe(data=>{
  
      this.data_famille=data;
      this.data_menu =[{'id_choix':1,'nom_menu':'AJOUTER PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'AJOUTER FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'AJOUTER SOUSCRIP.EAU'},{'id_choix':4,'nom_menu':'DECLARER DECES'}]
 
     
    
      
     
     
     

  })
  }

  deconnexion()
    {
      Swal.fire({
        title: 'Voulez vous vraiment vous deconnecter ?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          localStorage.clear()
        location.reload()
        } else if (result.isDenied) {
          
        }
      })
      
    }

  menu(id:number)
  {

       //PERSONNE
       if(id==1)
       {
     
         const modalRef = this.modalService.open(PersonneMenuC);
           modalRef.result.then(
               (result) => {
                this.liste_personne();
                this.liste_mvmt();
                this.liste_famille();
               
               },
               (reason) => {
                  
                  if(reason==undefined)
                  {
                    this.liste_personne();
                    this.liste_personne();
                    this.liste_mvmt();
                    this.liste_famille();
                  
                  }
               }
             );
       }

       //FAMILLE
       if(id==2)
       {
     
         const modalRef = this.modalService.open(FamilleMenuC);
           modalRef.result.then(
               (result) => {
                
                this.liste_personne();
                this.liste_mvmt();
                this.liste_famille();

               },
               (reason) => {
                  
                  if(reason==undefined)
                  {
                    
                    this.liste_personne();
                    this.liste_mvmt();
                    this.liste_famille();
                  }
               }
             );
       }


      //ABONNEMENT EAU
      if(id==3)
      {
    
        const modalRef = this.modalService.open(AbonnEauC);
          modalRef.result.then(
              (result) => {
                this.liste_personne();
                this.liste_mvmt();
                this.liste_famille();
              },
              (reason) => {
                 
                 if(reason==undefined)
                 {
                  this.liste_personne();
                  this.liste_mvmt();
                  this.liste_famille();
                 }
              }
            );
      }

      
      //DECLARER DECES
      if(id==4)
      {
    
        const modalRef = this.modalService.open(DecesAjouterC);
          modalRef.result.then(
              (result) => {
                this.liste_personne();
                this.liste_mvmt();
                this.liste_famille();
              },
              (reason) => {
                 
                 if(reason==undefined)
                 {
                  this.liste_personne();
                  this.liste_mvmt();
                  this.liste_famille();
                 }
              }
            );
      }




       
  }

  ouvrir(id_choix:number)
  {
    //EAU
    if(id_choix==1)
  {

    const modalRef = this.modalService.open(PaiementEauC);
      modalRef.result.then(
          (result) => {
            this.liste_personne();
            this.liste_mvmt();
            this.liste_famille();
          },
          (reason) => {
             
             if(reason==undefined)
             {
                this.liste_personne();
                this.liste_mvmt();
                this.liste_famille();
             }
          }
        );
  }


  //FONCIER
  if(id_choix==2)
  {

    const modalRef = this.modalService.open(PaiementFoncierC);
      modalRef.result.then(
          (result) => {
            this.liste_personne();
            this.liste_mvmt();
            this.liste_famille();
          },
          (reason) => {
             
             if(reason==undefined)
             {
                this.liste_personne();
                this.liste_mvmt();
                this.liste_famille();
             }
          }
        );
  }

  //PaiementFuneraireC

  if(id_choix==3)
  {

    const modalRef = this.modalService.open(PaiementFuneraireC);
      modalRef.result.then(
          (result) => {
            this.liste_personne();
            this.liste_mvmt();
            this.liste_famille();
          },
          (reason) => {
             
             if(reason==undefined)
             {
                this.liste_personne();
            this.liste_mvmt();
            this.liste_famille();
             }
          }
        );
  }
  //Paiement Location

  if(id_choix==4)
  {

    const modalRef = this.modalService.open(PaiementSallesC);
      modalRef.result.then(
          (result) => {
       
          },
          (reason) => {
             
             if(reason==undefined)
             {
             
             }
          }
        );
  }


  }

  choix(id: number)
  {

    
if(id==1)
{

  this.menu_clic=1
}
if(id==2)
{
  this.menu_clic=2
}
if(id==3)
{
  this.menu_clic=3
}

  }
}
@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> PAIEMENT ABONNEMENT EAU </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-4">
    Personne
    </div>
    <div class="col-8">
    <select class="form-control" [(ngModel)]="id_pers" [ngModelOptions]="{standalone: true}" (change)="get_type_pers()" >
    <option value="0"></option>
      <option *ngFor="let item of data_personne" value="{{item.id_pers}}">{{item.matricule_pers}} | {{item.nom_pers}} {{item.prenom_pers}}</option>
    
    </select>
  
    </div>
    </div>
    <br/>
    <div class="row" *ngIf="profil_pers!=''" >
    <div class="col-4" align="center">
    </div>
    <div class="col-3" align="center">

    

    </div>
    <div class="col-5" align="center">
    <div class="card">
    <img style="width:100%" height="250" width="250" src="{{base_url}}/apicaissevillage/personne/upload/{{profil_pers}}" />
  
 
</div>
    
    </div>
  

    </div>
    <div class="row">
    
    <div class="col-6">
    Mois concerné 
    </div>
    <div class="col-6">
    <input type="month" [(ngModel)]="mois_debut" (change)="calcul_mois()" class="form-control"/>
    </div>
   
    </div>
    
    <br/>
    <div class="row">
    <div class="col-6">
    Montant 
    </div>
    <div class="col-6">
    <input disabled [(ngModel)]="montant_a_verse" type="text" class="form-control" />
    </div>
   
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Montant Verse
    </div>
    <div class="col-6">
    <input   type="text" (keyup)="calcul_monnaie($event)" [(ngModel)]="montant_verse"  class="form-control" />
    </div>
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Monnaie à Rendre
    </div>
    <div class="col-6">
    <input  [(ngModel)]="monnaie_rendu"  type="text" disabled class="form-control" />
    </div>
    </div>
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="payer()" >Payer</button>
    </div>
  `
})
export class PaiementEauC {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
id_pers:any=0;
color1 : any=''
data_personne : any=[];
base_url : any=Urlbase;
mois_debut : any;
mois_fin: any;
nbmois : any=0;
data_config: any=[];
libelle_config :any="";
montant_a_eau : any=0;
data_type_pers: any=[];
montant_verse : any=0;
monnaie_rendu : any=0;
montant_a_verse : any=0;
id_ut : any=0;
profil_pers : any="";
data : any=[];
  constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    this.liste_personne();

  }
  payer()
  {
    if( this.id_pers==0 || this.montant_verse==0 || this.mois_debut=="" )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
         
            postData.append('mois_debut' , this.mois_debut);
      
            postData.append('id_pers' , this.id_pers);
            postData.append('montant_pay_ab' , this.montant_a_verse);
            postData.append('id_ut' ,this.id_ut );
            
            postData.append('montant_verse' ,this.montant_verse );
            postData.append('monnaie_rendu' ,this.monnaie_rendu );
       
            
           
            this.http.post(this.base_url+'/apicaissevillage/eau/payer_eau.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Abonnement payée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll();
              const modalRef = this.modalService.open(modalTicket);
            
              modalRef.componentInstance.numero_ticket=this.data['numero_ticket'];
              modalRef.componentInstance.date_ticket=this.data['date_ticket'];
              modalRef.componentInstance.heure_ticket=this.data['heure_ticket'];
              modalRef.componentInstance.libelle_ticket=this.data['libelle_ticket'];
              modalRef.componentInstance.type_ticket=this.data['type_ticket'];
              modalRef.componentInstance.montant_a_payer=this.data['montant_a_payer'];
              modalRef.componentInstance.montant_verse=this.data['montant_verse'];
              modalRef.componentInstance.monnaie=this.data['monnaie'];
              modalRef.componentInstance.caissier=this.data['caissier'];
  
              
              modalRef.result.then(
                  (result) => {
                 
                  
                  },
                  (reason) => {
                     
                     if(reason==undefined)
                     {
                    
                     
                     }
                  }
                );
            })
            // this.liste_cat()
            
             }
             else
             {
                console.log(data); 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue / Abonnement pour ce mois déja payé',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }

  }
  
  calcul_monnaie(event: any)
  {
this.monnaie_rendu=this.montant_verse-this.montant_a_verse;
  }
  config_prix(type : string)
  {
    
      this.libelle_config="A_EAU"
      
      this.http.get(this.base_url+'/apicaissevillage/config/liste_config.php?libelle_config='+this.libelle_config+"&type_config="+type).subscribe(data=>{
    
        this.data_config=data;
        console.log(data);
        this.montant_a_verse=this.data_config[0]['prix_config'];
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
    
  }
  get_type_pers()
  {
   
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?id_pers='+this.id_pers).subscribe(data=>{
  
      this.data_type_pers=data;
      this.profil_pers=this.data_type_pers[0]["profil_pers"];
      this.config_prix(this.data_type_pers[0]["type_pers"])
      
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  calcul_mois()
  {
    if(this.mois_debut=='' || this.mois_fin=='')
    {

    }
    else
    {
      var date1 = new Date(this.mois_debut);
      var date2 = new Date(this.mois_fin); 
      var months
      months = (date2.getFullYear() - date1.getFullYear()) * 12;
      months -= date1.getMonth();
      months += date2.getMonth();
      this.nbmois= months <= 0 ? 0 : months;
      this.nbmois=this.nbmois+1;
    
    }
  }
  liste_personne()
  {
    let id=0;
    this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php').subscribe(data=>{
  
      this.data_personne=data;
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  ngOnInit(): void {
   
  
    
    
  }
  typeoccup()
  {
    
  }

 
}

@Component({
  template: `
  
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> PAIEMENT FONCIER </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-4">
    Personne
    </div>
    <div class="col-8">

    <select class="form-control" id="selUser"  [(ngModel)]="id_pers" (change)="getimage()" [ngModelOptions]="{standalone: true}" >
    <option data-tokens="0" value="0"></option>
      <option *ngFor="let item of data_personne" value="{{item.id_pers}}"  >{{item.matricule_pers}} | {{item.nom_pers}} {{item.prenom_pers}}</option>
    
    </select>
  
    </div>
    </div>
   <br/>

    <div class="row" *ngIf="profil_pers!=''" >
    <div class="col-4" align="center">
    </div>
    <div class="col-3" align="center">

    

    </div>
    <div class="col-5" align="center">
    <div class="card">
    <img style="width:100%" height="250" width="250" src="{{base_url}}/apicaissevillage/personne/upload/{{profil_pers}}" />
  
 
</div>
    
    </div>
  

    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Numero Document
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="numero_sign_foncier" class="form-control" />
    </div>
   
    </div>
    <br/>
    <div class="row">
    <div class="col-4">
    Type 
    </div>
    <div class="col-8">
   <select class="form-control" [(ngModel)]="type_foncier" (change)="calcul()">
   <option value="">--</option>
   <option value="AV">ATTESTATION VILLAGEOISE</option>
 
   <option value="AL">AUTORISATION LOTISSEMENT</option>
   </select>
    </div>
   
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Superficie Terrain en M2
    </div>
    <div class="col-6">
    <input  type="number"  [(ngModel)]="superf_t" (keyup)="calcul_superf($event)" class="form-control" />
    </div>
   
    </div>
   
    <br/>
    <div class="row">
    <div class="col-6">
    Montant 
    </div>
    <div class="col-6">
    <input disabled [(ngModel)]="montant_a_verse" type="number" class="form-control" />
    </div>
   
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Montant Verse
    </div>
    <div class="col-6">
    <input   type="number" [(ngModel)]="montant_verse" (keyup)="calcul_monnaie($event)"  class="form-control" />
    </div>
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Monnaie à Rendre
    </div>
    <div class="col-6">
    <input   type="number" [(ngModel)]="monnaie_rendu" disabled class="form-control" />
    </div>
    </div>
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="payer()" >Payer</button>
    </div>
  `
})
export class PaiementFoncierC {
  
test_data: any;
libelle : any=''
data_price : any=[];
superf_t : any=0;
id_cat : any='';
color1 : any=''
data_personne : any=[];
montant_a_verse : any=0;
monnaie_rendu : any=0;
montant_verse : any=0;
type_foncier : any="";
numero_sign_foncier:any='';
base_url : any=Urlbase
id_pers : any=0;
id_ut : any=0;
data : any=[];
data_y : any=[];
profil_pers : any='';
  constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    this.liste_personne();

  }
  getimage()
  {
    
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?id_pers='+this.id_pers).subscribe(data=>{
  
      this.data_y=data;
      this.profil_pers=this.data_y[0]["profil_pers"];
      
      
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  payer()
  {
    if( this.id_pers==0 || this.numero_sign_foncier==0 || this.type_foncier=="" || this.superf_t==0 || this.montant_a_verse==0 || this.montant_verse==0 )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
         
            postData.append('type_sign_foncier' , this.type_foncier);
            postData.append('numero_sign_foncier' , this.numero_sign_foncier);
            postData.append('id_pers' , this.id_pers);
            postData.append('montant_sign_foncier' , this.montant_a_verse);
            postData.append('id_ut' ,this.id_ut );
            postData.append('montant_verse' ,this.montant_verse );
            postData.append('monnaie_rendu' ,this.monnaie_rendu );
            postData.append('super_sign_foncier' ,this.superf_t );
            
           
            this.http.post(this.base_url+'/apicaissevillage/foncier/paiement_foncier.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Foncier ajouté avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
              const modalRef = this.modalService.open(modalTicket);
            
              modalRef.componentInstance.numero_ticket=this.data['numero_ticket'];
              modalRef.componentInstance.date_ticket=this.data['date_ticket'];
              modalRef.componentInstance.heure_ticket=this.data['heure_ticket'];
              modalRef.componentInstance.libelle_ticket=this.data['libelle_ticket'];
              modalRef.componentInstance.type_ticket=this.data['type_ticket'];
              modalRef.componentInstance.montant_a_payer=this.data['montant_a_payer'];
              modalRef.componentInstance.montant_verse=this.data['montant_verse'];
              modalRef.componentInstance.monnaie=this.data['monnaie'];
              modalRef.componentInstance.caissier=this.data['caissier'];
  
              
              modalRef.result.then(
                  (result) => {
                 
                  
                  },
                  (reason) => {
                     
                     if(reason==undefined)
                     {
                    
                     
                     }
                  }
                );
            })
            // this.liste_cat()
            
             }
             else
             {
                console.log(data); 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }
  }
  calcul()
  {
    if(this.type_foncier=='AL')
    {
      this.montant_a_verse=1000000
    }
  }
  
  calcul_monnaie(event: any)
  {
this.monnaie_rendu=this.montant_verse-this.montant_a_verse;
  }
  calcul_superf(event: any)

  {
    if(this.type_foncier=='AL')
    {
     
    }
    else
    {
      if(this.superf_t>1500)
      {
        this.montant_a_verse=350* this.superf_t;
  
      }
      else
      {
        this.montant_a_verse=500000;
      }
  
    }
   
  }
  liste_personne()
  {
   // let type_pers="";
    let id=0;
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
  
      this.data_personne=data;
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  ngOnInit(): void {
   
  
    
    
  }
  typeoccup()
  {
    
  }

 
}

@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> ASSISTANCE FUNERAIRE </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-4">
    Famille
    </div>
    <div class="col-8">
    <select class="form-control" [(ngModel)]="id_famille" (change)="getphoto()" [ngModelOptions]="{standalone: true}" >
    <option value="0"></option>
      <option *ngFor="let item of data_famille" value="{{item.id_famille}}">{{item.nom_famille}} - {{item.nom_repres_famille}}</option>
    
    </select>
  
    </div>
    </div>
    <br/>
    <div class="row" *ngIf="profil_famille!=''" >
    <div class="col-4" align="center">
    </div>
    <div class="col-3" align="center">

    

    </div>
    <div class="col-5" align="center">
    <div class="card">
    <img style="width:100%" height="250" width="250" src="{{base_url}}/apicaissevillage/famille/upload/{{profil_famille}}" />
  
 
</div>
    
    </div>
  

    </div>
 <br/>
 <div class="row">
   
   

  
 <div class="col-6">
 Décès de
 </div>
 <div class="col-6">


 <select class="form-control" [(ngModel)]="id_deces" [ngModelOptions]="{standalone: true}" >
  <option value="0"></option>
    <option *ngFor="let item of data_deces" value="{{item.id_deces}}">{{item.date_deces}}-{{item.nom_deces}} {{item.prenom_deces}}</option>
  
  </select>

 </div>
 </div>
 <br/>
    <div class="row">
    <div class="col-6">
    Montant 
    </div>
    <div class="col-6">
    <input disabled [(ngModel)]="montant_a_verser"  type="number" class="form-control" />
    </div>
 
   
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Montant Verse
    </div>
    <div class="col-6">
    <input  [(ngModel)]="montant_verse" (keyup)="calcul_monnaie($event)"  type="number" class="form-control" />
    </div>
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Monnaie à Rendre
    </div>
    <div class="col-6">
    <input [(ngModel)]="monnaie_rendu"    type="number" disabled class="form-control" />
    </div>
    </div>
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="payer()" >Payer</button>
    </div>
  `
})
export class PaiementFuneraireC {
  mois_debut: any;
  mois_fin : any;
  nbmois: any=0;
test_data: any;
libelle : any=''
data_price : any=[];
id_deces : any=0;
id_cat : any='';
color1 : any='';
base_url : any=Urlbase;
data_famille : any=[];
id_famille : any=0;
data_deces : any=[];
libelle_config : any="";
montant_verse : any=0;
data_config : any=[];
montant_a_verser : any=0;
monnaie_rendu : any=0;
id_ut : any=0;
data_u:any=[];
data : any=[];
profil_famille : any="";
  constructor(private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    this.liste_famille();
    this.liste_deces();
    this.config_prix();

  }
  getphoto()
  {
    this.http.get(this.base_url+'/apicaissevillage/famille/liste_famille.php?id_famille='+this.id_famille).subscribe(data=>{
  
      this.data_u=data;
      this.profil_famille=this.data_u[0]['photo_famille'];
      
     
    
      
     
     
     

  })

  }
  payer()
  {
    if( this.id_famille==0 || this.id_deces==0 || this.montant_verse==0 )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
      if(this.montant_verse<this.montant_a_verser)
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Montant versé < montant à verser',
         
        })
      }
      else
      {
           //  alert(this.date_debut);
    
        
           let postData = new FormData();
           //alert(this.libelle)
             this.id_ut=localStorage.getItem('id_ut'); 
            
               postData.append('id_famille' , this.id_famille);
               postData.append('id_deces' , this.id_deces);
               postData.append('montant_verse' , this.montant_verse);
          
               postData.append('id_ut' ,this.id_ut );
               postData.append('montant_ass_fun' ,this.montant_a_verser );
               postData.append('monnaie_rendu' ,this.monnaie_rendu );
         
               
              
               this.http.post(this.base_url+'/apicaissevillage/funeraire/ajout_ass_funeraire.php', postData).subscribe(data=>{
                 this.data=data;
   
                 if(this.data['success']==true)
                 {
                    
            
                Swal.fire({
                 position: 'top-end',
                 icon: 'success',
                 title: 'Cotisation ajoutée avec succès !',
                 showConfirmButton: true,
                 timer: 1500
               }).then((result) => {
                 /* Read more about isConfirmed, isDenied below */
                 this.modalService.dismissAll()
                 const modalRef = this.modalService.open(modalTicket);
            
                 modalRef.componentInstance.numero_ticket=this.data['numero_ticket'];
                 modalRef.componentInstance.date_ticket=this.data['date_ticket'];
                 modalRef.componentInstance.heure_ticket=this.data['heure_ticket'];
                 modalRef.componentInstance.libelle_ticket=this.data['libelle_ticket'];
                 modalRef.componentInstance.type_ticket=this.data['type_ticket'];
                 modalRef.componentInstance.montant_a_payer=this.data['montant_a_payer'];
                 modalRef.componentInstance.montant_verse=this.data['montant_verse'];
                 modalRef.componentInstance.monnaie=this.data['monnaie'];
                 modalRef.componentInstance.caissier=this.data['caissier'];
     
                 
                 modalRef.result.then(
                     (result) => {
                    
                     
                     },
                     (reason) => {
                        
                        if(reason==undefined)
                        {
                       
                        
                        }
                     }
                   );
               })
               // this.liste_cat()
               
                }
                else
                {
                  if(this.data['cotisation']=='oui')
                  {
                    console.log(data); 
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Cotisation déja effectuée',
                     
                    })
                  }
                  else
                  {
                    console.log(data); 
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Erreur Inattendue',
                     
                    })
                  }
             
                    
                    
          
                }
            
   
               
                 
           
           
           
           })
      }
 
        
      
    


   
    }
  }
  calcul_monnaie(event : any)
  {
    this.monnaie_rendu=this.montant_verse-this.montant_a_verser;
  }

  config_prix()
  {
    this.libelle_config="FUN_AS"
   
    this.http.get(this.base_url+'/apicaissevillage/config/liste_config.php?libelle_config='+this.libelle_config).subscribe(data=>{
  
      this.data_config=data;
      console.log(data);
      this.montant_a_verser=this.data_config[0]['prix_config'];
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  liste_famille()
  {
    this.http.get(this.base_url+'/apicaissevillage/famille/liste_famille.php').subscribe(data=>{
  
      this.data_famille=data;
      
     
    
      
     
     
     

  })
  }
  liste_deces()
  {
    this.http.get(this.base_url+'/apicaissevillage/funeraire/liste_deces.php').subscribe(data=>{
  
      this.data_deces=data;
      
     
    
      
     
     
     

  })
  }

  ngOnInit(): void {
   
  
    
    
  }
  typeoccup()
  {
    
  }

 
}


@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> PAIEMENT LOCATION SALLES </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-6">
    Personne
    </div>
    <div class="col-6">
    <select class="form-control" [(ngModel)]="id_pers" [ngModelOptions]="{standalone: true}" (change)="get_type_pers()">
    <option value="0"></option>
      <option *ngFor="let item of data_personne" value="{{item.id_pers}}">{{item.nom_pers}} {{item.prenom_pers}}</option>
    
    </select>
    </div>
    </div>
    

    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Details Location
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="details_location" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>

    <div class="row">
   
   

  
    <div class="col-6">
    Date Debut Location
    </div>
    <div class="col-6">
    <input  [(ngModel)]="date_debut" type="date" (change)="calcul_nbjours()" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Date Fin Location
    </div>
    <div class="col-6">
    <input [(ngModel)]="date_fin" (change)="calcul_nbjours()"  type="date" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
    <div class="col-6">
    Choix Salle  | CLIMATISEE
    </div>
    <div class="col-6">
    <select     class="form-control"  [(ngModel)]="id_salle" (change)="get_prix()" >
   <option value=""></option>
   <option  *ngFor="let item of data_salles" value="{{item.id_salle}}">{{item.nom_salle}} | {{item.climatisee}}</option>
  
    </select>
    </div>
   
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Nombre de Jours 
    </div>
    <div class="col-6">
    <input disabled type="number" [(ngModel)]="nbjours"  class="form-control" />
    </div>
   
    </div>
    <br/>
  
    <div class="row">
    <div class="col-6">
    Montant / Jour
    </div>
    <div class="col-6">
    <input disabled type="number" [(ngModel)]="montant_unitaire" class="form-control" />
    </div>
   
    </div>
    <br/>

    <div class="row">
    <div class="col-6">
    Montant Total A Payer
    </div>
    <div class="col-6">
    <input disabled type="number" [(ngModel)]="montant_a_verse" class="form-control" />
    </div>
   
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Montant Verse
    </div>
    <div class="col-6">
    <input   type="number" [(ngModel)]="montant_verse"   (keyup)="calcul_monnaie($event)" class="form-control" />
    </div>
    </div>
    <br/>
    <div class="row">
    <div class="col-6">
    Monnaie à Rendre
    </div>
    <div class="col-6">
    <input   type="number" [(ngModel)]="monnaie_rendu" disabled class="form-control" />
    </div>
    </div>
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="payer()" >Payer</button>
    </div>
  `
})
export class PaiementSallesC {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
base_url : any=Urlbase;
data_personne : any=[];
id_pers : any=0;
details_location : any="";
date_debut : any="";
date_fin : any="";
id_salle : any=0;
nbjours : any=0;
montant_a_verse : any=0;
montant_verse :any=0;
monnaie_rendu : any=0;
data_type_pers : any=[];
data_salles : any=[];
montant_unitaire : any=0;
id_ut : any=0;
data : any=[];
  constructor(private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    this.liste_personne();

  }
  payer()
  {
    if( this.id_pers==0 || this.details_location=='' || this.date_debut=="" || this.date_fin=="" || this.id_salle==0 || this.id_salle==0 || this.nbjours==0 || this.montant_verse==0 )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut');
          postData.append('id_salle' , this.id_salle); 
          postData.append('details_location' , this.details_location); 
          postData.append('montant_location' , this.montant_a_verse); 
         
            postData.append('mois_debut' , this.date_debut);
      
            postData.append('id_pers' , this.id_pers);
            postData.append('id_ut' ,this.id_ut );
            postData.append('montant_verse' ,this.montant_verse );
            postData.append('debut_location' ,this.date_debut );
            postData.append('monnaie_rendu' ,this.monnaie_rendu );
            postData.append('fin_location' ,this.date_fin );
       
            
           
            this.http.post(this.base_url+'/apicaissevillage/salles/louer_salle.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Abonnement payée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
              const modalRef = this.modalService.open(modalTicket);
            
              modalRef.componentInstance.numero_ticket=this.data['numero_ticket'];
              modalRef.componentInstance.date_ticket=this.data['date_ticket'];
              modalRef.componentInstance.heure_ticket=this.data['heure_ticket'];
              modalRef.componentInstance.libelle_ticket=this.data['libelle_ticket'];
              modalRef.componentInstance.type_ticket=this.data['type_ticket'];
              modalRef.componentInstance.montant_a_payer=this.data['montant_a_payer'];
              modalRef.componentInstance.montant_verse=this.data['montant_verse'];
              modalRef.componentInstance.monnaie=this.data['monnaie'];
              modalRef.componentInstance.caissier=this.data['caissier'];
  
              
              modalRef.result.then(
                  (result) => {
                 
                  
                  },
                  (reason) => {
                     
                     if(reason==undefined)
                     {
                    
                     
                     }
                  }
                );
            })
            // this.liste_cat()
            
             }
             else
             {
                console.log(data); 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }

  }

  calcul_monnaie(event : any)
  {
this.monnaie_rendu=this.montant_verse-this.montant_a_verse;
  }
calcul_nbjours()
{

  if(this.date_debut=='' || this.date_fin=='')
  {

  }
  else
  {
    var date1 = new Date(this.date_debut);
    var date2 = new Date(this.date_fin); 
    var Time = date2.getTime() - date1.getTime(); 
    var Days = Time / (1000 * 3600 * 24); //Diference in Days
    this.nbjours=Days
    if(this.nbjours<0)
    {
      this.nbjours=0
    }
    this.montant_a_verse=this.nbjours*this.montant_unitaire;
  
  }
}
  get_prix()
  {
  

    this.http.get(this.base_url+'/apicaissevillage/salles/liste_salle.php?id_salle='+this.id_salle).subscribe(data=>{
  
      this.data_price=data;
      console.log(data);
      this.montant_unitaire=this.data_price[0]['prix'];
      this.montant_a_verse=this.nbjours*this.montant_unitaire;
    
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })

  }
  config_prix(type:string)
  {

    
    this.http.get(this.base_url+'/apicaissevillage/salles/liste_salle.php?type_salle='+type).subscribe(data=>{
  
      this.data_salles=data;
      console.log(data);
    
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })

  }
  get_type_pers()
  {
   
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?id_pers='+this.id_pers).subscribe(data=>{
  
      this.data_type_pers=data;
      this.config_prix(this.data_type_pers[0]["type_pers"])
      
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  liste_personne()
  {
   // let type_pers="";
    let id=0;
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
  
      this.data_personne=data;
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  ngOnInit(): void {
   
  
    
    
  }
  typeoccup()
  {
    
  }

 
}


@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> PERSONNE </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-6">
    Nom
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="nom_pers"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>

    <br/>

    <div class="row">
   
   

  
    <div class="col-6">
   Prenom
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="prenom_pers"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Telephone
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="telephone_pers"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
    <div class="col-6">
    Type personne
    </div>
    <div class="col-6">
    <select class="form-control" [(ngModel)]="type_pers"  [ngModelOptions]="{standalone: true}"  >
   <option value=""></option>
   <option value="AU">AUTOCHTONE</option>
   <option value="AL">HALOGENE</option>
    </select>
    </div>
   
    </div>
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="valider()" >AJOUTER</button>
    </div>
  `
})
export class PersonneMenuC {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
nom_pers : any="";
telephone_pers : any="";
type_pers : any="";
prenom_pers : any="";
base_url : any=Urlbase;
data : any;
  id_ut: any;
  constructor(private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)

  }
  ngOnInit(): void {
   
  
    
    
  }
  
  getRandomString(nom,prenom,length) {

    var randomChars = '0123456789';
    var result = 'CI'+nom.charAt(0)+prenom.charAt(0)+'-';
    for ( var i = 0; i < (length/2); i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    var Radomstring='67851236545'
    for ( var i = 0; i < 3; i++ ) {
      result +=  Radomstring.charAt(Math.floor(Math.random() * Radomstring.length));
  }
    
    return result
}
  valider()
  {
    
    if( this.nom_pers=='' || this.prenom_pers=='' || this.type_pers=='' || this.telephone_pers=="" )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
          postData.append('matricule_pers',this.getRandomString(this.nom_pers,this.prenom_pers,10));
         
            postData.append('nom_pers' , this.nom_pers);
            postData.append('prenom_pers' , this.prenom_pers);
            postData.append('type_pers' , this.type_pers);
            postData.append('telephone_pers' , this.telephone_pers);
            postData.append('id_ut' ,this.id_ut );
            
           
            this.http.post(this.base_url+'/apicaissevillage/personne/ajouter_personne.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Personne ajoutée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
            })
            // this.liste_cat()
            
             }
             else
             {
                 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }

  }
 
 
}


@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> FAMILLE </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-6">
    Nom Famille
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="nom_famille"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>

    <br/>

    <div class="row">
   
   

  
    <div class="col-6">
   Nom Representant Famille
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="nom_repres_famille"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Telephone Representant Famille
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="tel_repres_famille"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
   
   
   
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajouter()" >AJOUTER</button>
    </div>
  `
})
export class FamilleMenuC {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
 nom_famille : any="";
 nom_repres_famille : any="";
 tel_repres_famille : any="";
 id_ut : any=0;
 base_url : any=Urlbase;
 data : any;
  constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)

  }
  ngOnInit(): void {
   
  
    
    
  }
  ajouter()
  {
    if( this.nom_famille=='' || this.nom_repres_famille=='' || this.tel_repres_famille=='' )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
         
            postData.append('nom_famille' , this.nom_famille);
            postData.append('nom_repres_famille' , this.nom_repres_famille);
            postData.append('tel_repres_famille' , this.tel_repres_famille);
            
            postData.append('id_ut' ,this.id_ut );
            
           
            this.http.post(this.base_url+'/apicaissevillage/famille/ajouter_famille.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Famille ajoutée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
            })
            // this.liste_cat()
            
             }
             else
             {
                 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }
    
  }

 
}

@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> SOUSCRIPTION EAU </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-4">
    Nom Prenom
    </div>
    <div class="col-8">
  <select class="form-control" [(ngModel)]="id_pers" [ngModelOptions]="{standalone: true}" (change)="get_type_pers()">
  <option value="0"></option>
    <option *ngFor="let item of data_personne" value="{{item.id_pers}}">{{item.matricule_pers}} | {{item.nom_pers}} {{item.prenom_pers}}</option>
  
  </select>
    </div>
    </div>

    <br/>
    <div class="row" *ngIf="profil_pers!=''" >
    <div class="col-4" align="center">
    </div>
    <div class="col-3" align="center">

    

    </div>
    <div class="col-5" align="center">
    <div class="card">
    <img style="width:100%" height="250" width="250" src="{{base_url}}/apicaissevillage/personne/upload/{{profil_pers}}" />
  
 
</div>
    
    </div>
  

    </div>

    <div class="row">
   
   

  
    <div class="col-6">
   Montant à Payer <i *ngIf="type_pers=='AL'" style="color:red">(ALLOGENE)</i> <i  style="color:red" *ngIf="type_pers=='AU'">(AUTOCHT.)</i>
    </div>
    <div class="col-6" >
    <input  type="number" [(ngModel)]="montant_s_eau" [ngModelOptions]="{standalone: true}" disabled class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Montant Versé
    </div>
    <div class="col-6">
    <input  type="number" [(ngModel)]="montant_verse" (keyup)="calcul_monnaie($event)"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Monnaie Rendue
    </div>
    <div class="col-6">
    <input  type="number" [(ngModel)]="monnaie_rendu" [ngModelOptions]="{standalone: true}" disabled class="form-control" />
  
    </div>
    </div>
   
   
   
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajouter()" >AJOUTER</button>
    </div>
  `
})
export class AbonnEauC {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
id_pers:any=0;
montant_s_eau : any=0;
montant_verse : any=0;
monnaie_rendu : any=0;
base_url : any=Urlbase;
data_personne: any=[];
data_config : any=[];
libelle_config : any="";
type_config : any="";
data_type_pers : any=[];
id_ut : any=0;
data : any;
type_pers : any="";
profil_pers :any="";
  constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    this.liste_personne();

  }
  get_type_pers()
  {
   
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?id_pers='+this.id_pers).subscribe(data=>{
  
      this.data_type_pers=data;
      this.config(this.data_type_pers[0]["type_pers"])
      this.profil_pers=this.data_type_pers[0]["profil_pers"];
      this.type_pers=this.data_type_pers[0]["type_pers"];
      console.log(this.data_type_pers[0]["type_pers"])
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  config(type :string)
  {
    this.libelle_config="S_EAU"
    this.type_config="";
    this.http.get(this.base_url+'/apicaissevillage/config/liste_config.php?libelle_config='+this.libelle_config+"&type_config="+type).subscribe(data=>{
  
      this.data_config=data;
      console.log(data);
      this.montant_s_eau=this.data_config[0]['prix_config'];
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  
  calcul_monnaie(event : any)
  {
this.monnaie_rendu=this.montant_verse-this.montant_s_eau;
  }
  liste_personne()
  {
   // let type_pers="";
    let id=0;
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
  
      this.data_personne=data;
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  ajouter()
  {
    if( this.id_pers==0 || this.montant_s_eau==0 || this.montant_verse==0)
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
    if(this.montant_s_eau>this.montant_verse)
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Montant Versé < Montant à Verser',
       
      })
         
    }
    else
    {
      let postData = new FormData();
      //alert(this.libelle)
        this.id_ut=localStorage.getItem('id_ut'); 
       
          postData.append('id_pers' , this.id_pers);
          postData.append('montant_s_eau' , this.montant_s_eau);
          postData.append('montant_verse' , this.montant_verse);
          postData.append('monnaie_rendu' , this.monnaie_rendu);
          
          postData.append('id_ut' ,this.id_ut );
          
         
          this.http.post(this.base_url+'/apicaissevillage/eau/souscrire_eau.php', postData).subscribe(data=>{
            this.data=data;

            if(this.data['success']==true)
            {
               
       
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Souscription ajoutée avec succès !',
            showConfirmButton: true,
            timer: 1500
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            this.modalService.dismissAll()
            const modalRef = this.modalService.open(modalTicket);
            
            modalRef.componentInstance.numero_ticket=this.data['numero_ticket'];
            modalRef.componentInstance.date_ticket=this.data['date_ticket'];
            modalRef.componentInstance.heure_ticket=this.data['heure_ticket'];
            modalRef.componentInstance.libelle_ticket=this.data['libelle_ticket'];
            modalRef.componentInstance.type_ticket=this.data['type_ticket'];
            modalRef.componentInstance.montant_a_payer=this.data['montant_a_payer'];
            modalRef.componentInstance.montant_verse=this.data['montant_verse'];
            modalRef.componentInstance.monnaie=this.data['monnaie'];
            modalRef.componentInstance.caissier=this.data['caissier'];

            
            modalRef.result.then(
                (result) => {
               
                
                },
                (reason) => {
                   
                   if(reason==undefined)
                   {
                  
                   
                   }
                }
              );
          })
          // this.liste_cat()
          
           }
           else
           {
             console.log(data)
               
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Erreur Inattendue / Abonnement déja effectué',
             
            })
               
               
     
           }
       

          
            
      
      
      
      })
      
    }
        
   
      
    


   
    }
  }
  ngOnInit(): void {
   
  
    
    
  }
  typeoccup()
  {
    
  }

 
}


@Component({
  template: `
  <div class="modal-content" style="width:150% !important; height:20% !important">
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> VERIFICATION RECUS </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
    <div class="col-12">

    
    <div class="table-wrapper-scroll-y my-custom-scrollbar">
    <div  *ngIf="data_mvt==0" style="color:red">
NUMERO DE TICKET NON RETENU
    </div>

    <table class="table table-bordered table-striped mb-0">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">DATE</th>
          <th scope="col">LIBELLE</th>
          <th scope="col">PAYE</th>
          <th scope="col">A PAYER</th>
          <th scope="col">MONNAIE</th>
          
        
        </tr>
      </thead>
      <tbody>
       <tr  *ngFor="let item of data_mvt" >
       <td>
       {{item.id_mvt}}
       </td>
       <td>
       {{item.date_mvt}}
       </td>
       <td>
       {{item.libelle_mvt}}
       </td>
       <td>
       {{item.montant_verse_mvt}}
       </td>
       <td>
       {{item.montant_a_verse_mvt}}
       </td>
       <td>
       {{item.monnaie_rendue}}
       </td>
        </tr>
 
      </tbody>
    
    </table>
    <br/>
    <p align="center" *ngIf="type_details=='EAU_P'"><b>ABONNEMENT EAU</b>
    <br/>

    </p>
  
    <table *ngIf="type_details=='EAU_P'" class="table table-bordered table-striped mb-0">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom Personne</th>
        <th scope="col">Date Paiement </th>
        <th scope="col">Montant Payé</th>
        <th scope="col">Montant Versé</th>
        <th scope="col">Monnaie</th>
        
      
      </tr>
    </thead>
    <tbody>
     <tr *ngFor="let item of data_details">
     <td>
     {{item.id_ab}}
     </td>
   
     <td>
     {{item.nom_pers}}   {{item.prenom_pers}}
     </td>
     <td>
     {{item.date_pay_ab}}
     </td>
     <td>
     {{item.montant_pay_ab}}
     </td>
     <td>
     {{item.montant_verse}}
     </td>
     <td>
     {{item.monnaie_rendu}}
     </td>
      </tr>

      

    </tbody>
  
  </table>


  
  <p align="center" *ngIf="type_details=='EAU_S'"><b>SOUSCRIPTION EAU</b>
  
  <br/>
</p>

  <table *ngIf="type_details=='EAU_S'" class="table table-bordered table-striped mb-0">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Nom Personne</th>
      <th scope="col">Date Paiement </th>
      <th scope="col">Montant à Payer</th>
      <th scope="col">Montant Versé</th>
      <th scope="col">Monnaie</th>
      
    
    </tr>
  </thead>
  <tbody>
   <tr  *ngFor="let item of data_details" >
   <td>
   {{item.id_s_eau}}
   </td>
   <td>
   {{item.nom_pers}} {{item.prenom_pers}}
   </td>
   <td>
   {{item.date_s_eau}}
   </td>
   <td>
   {{item.montant_s_eau}}
   </td>
   <td>
   {{item.montant_verse}}
   </td>
   <td>
   {{item.monnaie_rendu}}
   </td>
    </tr>

    

  </tbody>

</table>



<p align="center" *ngIf="type_details=='FUNERAIRE'"><b>ASSISTANCE FUNERAIRE</b>
<br/>

</p>

<table *ngIf="type_details=='FUNERAIRE'" class="table table-bordered table-striped mb-0">
<thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Nom Famille</th>
    <th scope="col">Date Paiement </th>
    <th scope="col">Montant Payé</th>
    <th scope="col">Montant Versé</th>
    <th scope="col">Monnaie</th>
    
   
  </tr>
</thead>
<tbody>
 <tr *ngFor="let item of data_details">
 <td>
 {{item.id_ass_fun}}
 </td>
 <td>
 {{item.nom_famille}}
 </td>
 <td>
 {{item.date_pay_ass_fun}}
 </td>
 <td>
 {{item.montant_ass_fun}}
 </td>
 <td>
 {{item.montant_verse}}
 </td>
 <td>
 {{item.monnaie_rendue}}
 </td>
  </tr>

  

</tbody>

</table>


<p align="center" *ngIf="type_details=='FONCIER'"><b>FONCIER</b>

<br/>
</p>
<table *ngIf="type_details=='FONCIER'" class="table table-bordered table-striped mb-0">
<thead>
  <tr>
    <th scope="col"> Doc</th>
    <th scope="col">Nom Personne</th>
    <th scope="col">Type Signature</th>
    <th scope="col">Date </th>
    <th scope="col"> Payé</th>
    <th scope="col"> Versé</th>
    <th scope="col">Monnaie</th>
    
    
  </tr>
</thead>
<tbody>
 <tr *ngFor="let item of data_details">
 <td>
 {{item.numero_sign_foncier}}
 </td>
 <td>

 {{item.nom_pers}}  {{item.prenom_pers}}
 </td>
 <td>
 {{item.type_sign_foncier}}
 </td>
 <td>
 {{item.date_sign_foncier}}
 </td>
 <td>
 {{item.montant_sign_foncier}}
 </td>
 <td>
 {{item.montant_verse}}
 </td>
 <td>
 {{item.monnaie_rendu}}
 </td>

  </tr>

  

</tbody>

</table>
  

<p align="center" *ngIf="type_details=='SALLE'"><b>LOCATION SALLES</b>

<br/>
</p>
<table *ngIf="type_details=='SALLE'" class="table table-bordered table-striped mb-0">
<thead>
  <tr>
    <th scope="col"> Salle</th>
    <th scope="col">Nom Personne</th>
    <th scope="col">Details Location</th>
    <th scope="col">Periode </th>
    <th scope="col"> Payé</th>
    <th scope="col"> Versé</th>
    <th scope="col">Monnaie</th>
    
    
  </tr>
</thead>
<tbody>
 <tr  *ngFor="let item of data_details">
 <td>
 {{item.nom_salle}}
 </td>
 <td>
 {{item.nom_pers}} {{item.prenom_pers}}
 </td>
 <td>
 {{item.details_location}}
 </td>
 <td>
 {{item.debut_location}} - {{item.fin_location}}
 </td>
 <td>
 {{item.montant_location}}
 </td>
 <td>
 {{item.montant_verse}}
 </td>
 <td>
 {{item.monnaie_rendu}}
 </td>
  </tr>

  

</tbody>

</table>
  

  
  </div>

    </div>
   

   
    </div>

    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
     
    </div>
    </div>
  `
})
export class VerifierModalC {
@Input() public numero_mvt:any;
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
 nom_famille : any="";
 nom_repres_famille : any="";
 tel_repres_famille : any="";
 id_ut : any=0;
 base_url : any=Urlbase;
 data : any;
 data_mvt : any=[];
 type_details : any="";
 data_details : any=[];
 supprimer_recu:any=0;
  constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
  

  }
  ngOnInit(): void {
   
   // alert(this.numero_mvt);
   this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?numero_ticket='+this.numero_mvt+'&etat=1').subscribe(data=>{
  
    this.data_mvt=data;
 
    this.type_details=this.data_mvt[0]['type_mvt'];
    this.supprimer_recu=this.data_mvt[0]['etat'];
   

    if (this.type_details=="EAU_S")
    {
      let id=0;
      this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php?id_s_eau='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{
    
        this.data_details=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })

    }
    if (this.type_details=="FONCIER")
    {

    
      let id=0;
      this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php?id_sign_foncier='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{

        console.log(data)
    
        this.data_details=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })


    }

    if (this.type_details=="FUNERAIRE")
    {

      let id=0;
      this.http.get(this.base_url+'/apicaissevillage/funeraire/liste_paiement_funeraire.php?id_ass_fun='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{

        console.log(data)
    
        this.data_details=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })

    }

    if (this.type_details=="EAU_P")
    {

      let id=0;
      this.http.get(this.base_url+'/apicaissevillage/eau/liste_paiement.php?id_ab='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{

        console.log(data)
    
        this.data_details=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })

    }

    if (this.type_details=="SALLE")
    {

      this.http.get(this.base_url+'/apicaissevillage/salles/liste_location_salle.php?id_location='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{

        console.log(data)
    
        this.data_details=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })

    }

  
    
   
   
   

})
    
  }
  ajouter()
  {
    if( this.nom_famille=='' || this.nom_repres_famille=='' || this.tel_repres_famille=='' )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
         
            postData.append('nom_famille' , this.nom_famille);
            postData.append('nom_repres_famille' , this.nom_repres_famille);
            postData.append('tel_repres_famille' , this.tel_repres_famille);
            
            postData.append('id_ut' ,this.id_ut );
            
           
            this.http.post(this.base_url+'/apicaissevillage/famille/ajouter_famille.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Famille ajoutée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
            })
            // this.liste_cat()
            
             }
             else
             {
                 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }
    
  }

 
}



@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> DECLARER DECES </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-6">
    Nom Décédé
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="nom_deces"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>

    <br/>

    <div class="row">
   
   

  
    <div class="col-6">
  Prenom Décédé
    </div>
    <div class="col-6">
    <input  type="text" [(ngModel)]="prenom_deces"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Date Décès
    </div>
    <div class="col-6">
    <input  type="date" [(ngModel)]="date_deces"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
   
   
   
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajouter()" >AJOUTER</button>
    </div>
  `
})
export class DecesAjouterC {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
 nom_deces : any="";
 prenom_deces : any="";
 date_deces : any="";
 id_ut : any=0;
 base_url : any=Urlbase;
 data : any;
  constructor(private datePipe : DatePipe, private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)

  }
  ngOnInit(): void {
   
  
    
    
  }
  ajouter()
  {
    if( this.nom_deces=='' || this.prenom_deces=='' || this.date_deces=='' )
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
        
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
         
            postData.append('nom_deces' , this.nom_deces);
            postData.append('prenom_deces' , this.prenom_deces);
           
            postData.append('date_deces' , this.datePipe.transform(this.date_deces,"dd/MM/yyyy"));
            postData.append('mois_deces' , this.datePipe.transform(this.date_deces,"MM"));
            postData.append('annee_deces' , this.datePipe.transform(this.date_deces,"yyyy"));
            
            postData.append('id_ut' ,this.id_ut );
            
           
            this.http.post(this.base_url+'/apicaissevillage/funeraire/aj_deces.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Declaration de Décès ajoutée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
            })
            // this.liste_cat()
            
             }
             else
             {
                 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    


   
    }
    
  }

 
}

@Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> RECU  </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="" >
     
     
      <div id="wrapper">
      <table border="0" style="border-collapse: collapse; width: 100%; height: auto;">
          <tbody><tr>
              <td width="100%" align="center">
                  <center>
                      
                  </center>
              </td>
          </tr>
       
          <tr>
              <td width="100%" align="center">
              
                  <span class="left" style="text-align: left;font-size:18px">TICKET ID : <b>{{numero_ticket}}</b></span>
                  <br/>
                  <br/>
                  <img height="100" src="assets/img/logo.png" />
              
                  <br/>
                  
                  <span class="left" style="text-align: left;">Date: {{date_ticket}} à {{heure_ticket}} </span>
                  
              </td>
          </tr>   
      </tbody></table>
      
      
          
      
      
      <table class="table  table-active" cellspacing="0" border="1"> 
          <thead> 
              <tr> 
                 
                  <th width="45%" align="left">Libel.</th>
                  <th width="10%">Qté</th>
                  <th width="25%">P.U.</th>
                  <th width="25%">RECU</th>
                  <th width="20%" align="right">RENDUE</th> 
              </tr> 
          </thead> 
          <tbody> 
    				<tr>
                     
                      <td width="35%" valign="top"> {{libelle_ticket}}   </td>
                      <td width="10%" valign="top">1</td>
                      <td width="25%" valign="top"> {{montant_a_payer}} </td>
                      <td width="20%" valign="top">{{montant_verse}}</td>
                      <td width="20%" valign="top">{{monnaie}}</td>
                  </tr>	
                          
             
                       
          </tbody> 
      </table> 
      
      
    
      
      <div style="border-top:1px solid #000; padding-top:10px;">
         
          <p>Merci de votre visite! </p> 

          <p>A Bientot!</p> 
          <b>caissier : {{caissier}}</b>
         
      </div>

      <div align="center">
      <qr-code height="50px" [value]="qrInfo"></qr-code>
      
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      ---------------
     
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="imprimer()">IMPRIMER</button>
      </div>
    `
  })
  export class modalTicket {
    @Input() public numero_ticket:any;
    @Input() public date_ticket:any;
    @Input() public heure_ticket:any;
    @Input() public libelle_ticket:any;
    @Input() public type_ticket:any;
    @Input() public montant_a_payer:any;
    @Input() public montant_verse:any;
    @Input() public monnaie:any;
    @Input() public caissier:any;
    item : any=[];
    qrInfo : any;
  
   base_url : any=Urlbase;
   data : any;
    constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
     
  
    }
    imprimer()
    {
        let printContents, popupWin;
        printContents = document.getElementById('wrapper').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              
            
            @media print {
                body { text-transform: uppercase; }
                #buttons { display: none; }
                #wrapper { width: 100%; margin: 0; font-size:12px; }
                #wrapper img { max-width:500px; width: 100%; }
                #bkpos_wrp{
                    display: none;
                }
            }
            @page {   
              margin-top: 0;  
              margin-bottom: 0;  
            }
        
              </style>
            </head>
        <body style="receipt" onload="window.print();window.close()">${printContents}</body>
          </html>`
        );
        popupWin.document.close();  
    }
    ngOnInit(): void {
     
        this.item = [{
            'numero ticket': this.numero_ticket,
            'date ': this.date_ticket,
            'heure': this.heure_ticket,
            'montant payé': this.montant_a_payer,
            'type' : this.type_ticket
          }]
          this.qrInfo = JSON.stringify(this.item);
      
      
    }
  
  
   
  }