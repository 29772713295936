import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export var ROUTES: RouteInfo[] = [
   
   
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    constructor()
    {
      if( localStorage.getItem('type_ut')=='CAISSE')
      {
        ROUTES= [
            { path: '/dashboard',     title: 'Tableau de Bord',         icon:'nc-layout-11',       class: '' },
        { path: '/mouvements',    title: 'Mouvements',        icon:'nc-bullet-list-67', class: '' }
 ]
      }

      if(localStorage.getItem('type_ut')=='ADMIN')
      {
          ROUTES= [{ path: '/dashboard',     title: 'Tableau de Bord',         icon:'nc-layout-11',       class: '' },
          { path: '/icons',         title: 'Gestion des Personnes',             icon:'nc-user-run',    class: '' },
          { path: '/famille',         title: 'Gestion des Familles',             icon:'nc-single-02',    class: '' },
          { path: '/souscription',          title: 'Souscription - Eau',              icon:'nc-sound-wave',      class: '' },
          { path: '/maps',          title: 'Abonnement - Eau',              icon:'nc-sound-wave',      class: '' },
          { path: '/notifications', title: 'Gestion Funeraire',     icon:'nc-mobile',    class: '' },
          { path: '/deces', title: 'Gestion Des Décès',     icon:'nc-mobile',    class: '' },
          { path: '/user',          title: 'Gestion des Salles',      icon:'nc-shop',  class: '' },
          { path: '/table',         title: 'Gestion Fonciere',        icon:'nc-align-center',    class: '' },
          { path: '/mouvements',    title: 'Mouvements',        icon:'nc-bullet-list-67', class: '' },
          { path: '/utilisateurs',    title: 'Utilisateurs',        icon:'nc-single-02', class: '' },
          { path: '/typography',    title: 'Parametres',        icon:'nc-settings-gear-65', class: '' }]
      }
      if(localStorage.getItem('type_ut')=='PATRIMOINE')
      {
          ROUTES= [
          { path: '/icons',         title: 'Gestion des Personnes',             icon:'nc-user-run',    class: '' },
          { path: '/famille',         title: 'Gestion des Familles',             icon:'nc-single-02',    class: '' },
          { path: '/deces', title: 'Gestion Des Décès',     icon:'nc-mobile',    class: '' },
          { path: '/user',          title: 'Gestion des Salles',      icon:'nc-shop',  class: '' }]
      }
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
