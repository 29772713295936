import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import Swal from 'sweetalert2';
import { Urlbase } from './config/config';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  id_ut : any=0;
  base_url : any=Urlbase
  data :any=[];
  nom_ut : any="";
  prenom_ut : any="";
  type_ut : any="";
  constructor(private http : HttpClient,private router :Router ,private idle: Idle, private keepalive: Keepalive) {
   
    if(localStorage.getItem('id_ut')==undefined)
    {
      this.router.navigate(['connexion'])
    }
    else
    {
      this.id_ut=localStorage.getItem('id_ut')
      //dahb :
      if(localStorage.getItem('type_ut')=='ADMIN')
      {
        this.router.navigate(['dashboard'])
      }
      if(localStorage.getItem('type_ut')=='PATRIMOINE')
      {
        this.router.navigate(['dashboard'])
      }
      if(localStorage.getItem('type_ut')=='VERIFIEUR')
      {
        this.router.navigate(['verifieur'])

      }
      
      //caisse
      if(localStorage.getItem('type_ut')=='CAISSE')
      {
       
        this.router.navigate(['choix'])
        
      }

      
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(60);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      //this.router.navigate(['/']);
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
       // this.childModal.show();
       
       this.id_ut=localStorage.getItem('id_ut');
       this.nom_ut=localStorage.getItem('nom_ut');
       this.prenom_ut=localStorage.getItem('prenom_ut');
       this.type_ut=localStorage.getItem('type_ut');
       localStorage.clear();
       Swal.fire({
        title: 'Saisir à nouveau votre mot de passe pour rester connecter',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: false,
        confirmButtonText: 'Connexion',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {

         
       

        
          this.http.get(this.base_url+'/apicaissevillage/utilisateur/verif_mdp.php?id_ut='+this.id_ut+'&mdp_ut='+login).subscribe(data=>{
            
            localStorage.setItem('id_ut', this.id_ut);
            localStorage.setItem('nom_ut', this.nom_ut);
            localStorage.setItem('prenom_ut', this.prenom_ut);
            localStorage.setItem('type_ut', this.type_ut);
            this.data=data;
            console.log(data);
  
            if(this.data['success']==true)
            {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Connecté!',
                showConfirmButton: true,
                timer: 1500
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
            
              })
            }
            else
            {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Mot de passe eronné',
               
              }) 
localStorage.clear();
location.reload();
            }
           
          
            
           
           
           
      
        })
        
       
        },
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
        /*  Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url
          })*/
        }
      })
     
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
     
    }


  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
