import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CaisseComponent } from './pages/caisse/caisse.component';
import { ChoixComponent } from './pages/choix/choix.component';
import { LoginComponent } from './pages/login/login.component';
import { VerifieurComponent } from './pages/verifieur/verifieur.component';

export const AppRoutes: Routes = [
  {path : 'connexion', component : LoginComponent },
  {path : 'verifieur', component : VerifieurComponent },
  {path : 'caisse', component : CaisseComponent },
  {path : 'choix', component : ChoixComponent },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]},

]
