import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Urlbase } from 'app/config/config';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verifieur',
  templateUrl: './verifieur.component.html',
  styleUrls: ['./verifieur.component.scss']
})
export class VerifieurComponent implements OnInit {
  nom_ut : any="";
  mdp_ut: any="";
  base_url : any=Urlbase
  data : any;
  numero_ticket : any="";
  constructor( private modalService : NgbModal,private router : Router,private http: HttpClient) { 
    
    if(localStorage.getItem('id_ut')==undefined)
    {
      this.router.navigate(['connexion'])
    }
    else

    {
        this.nom_ut=localStorage.getItem('nom_ut') + ' ' +localStorage.getItem('prenom_ut')
      this.router.navigate(['verifieur'])
    }

  }
  deconnexion()
  {
      Swal.fire({
          title: 'Voulez vous vraiment vous deconnecter ?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Oui',
          denyButtonText: `Non`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            localStorage.clear()
          location.reload()
          } else if (result.isDenied) {
            
          }
        })
      
  }
  
  verifier()
  {
    if (this.numero_ticket=="")
    {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else
    {
      const modalRef = this.modalService.open(VerifierModalV);
      //alert("ff"+this.numero_mvt_v)
      modalRef.componentInstance.numero_mvt = this.numero_ticket;
      modalRef.result.then(
          (result) => {
          // this.liste_personne();
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
            //   this.liste_personne();
             
             }
          }
        );
    }
  
  }

  ngOnInit(): void {
  }

}
@Component({
    template: `
    <div class="modal-content" style="width:150% !important; height:20% !important">
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> VERIFICATION </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
      <div class="col-12">
  
      
      <div class="table-wrapper-scroll-y my-custom-scrollbar">
      <div  *ngIf="data_mvt==0" style="color:red">
  NUMERO DE TICKET NON RETENU
      </div>
  
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">DATE</th>
            <th scope="col">LIBELLE</th>
            <th scope="col">PAYE</th>
            <th scope="col">A PAYER</th>
            <th scope="col">MONNAIE</th>
            
          
          </tr>
        </thead>
        <tbody>
         <tr  *ngFor="let item of data_mvt" >
         <td>
         {{item.id_mvt}}
         </td>
         <td>
         {{item.date_mvt}}
         </td>
         <td>
         {{item.libelle_mvt}}
         </td>
         <td>
         {{item.montant_verse_mvt}}
         </td>
         <td>
         {{item.montant_a_verse_mvt}}
         </td>
         <td>
         {{item.monnaie_rendue}}
         </td>
          </tr>
   
        </tbody>
      
      </table>
      <br/>
      <p align="center" *ngIf="type_details=='EAU_P'"><b>ABONNEMENT EAU</b>
      <br/>
  
      </p>
    
      <table *ngIf="type_details=='EAU_P'" class="table table-bordered table-striped mb-0">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom Personne</th>
          <th scope="col">Date Paiement </th>
          <th scope="col">Montant Payé</th>
          <th scope="col">Montant Versé</th>
          <th scope="col">Monnaie</th>
          
        
        </tr>
      </thead>
      <tbody>
       <tr *ngFor="let item of data_details">
       <td>
       {{item.id_ab}}
       </td>
     
       <td>
       {{item.nom_pers}}   {{item.prenom_pers}}
       </td>
       <td>
       {{item.date_pay_ab}}
       </td>
       <td>
       {{item.montant_pay_ab}}
       </td>
       <td>
       {{item.montant_verse}}
       </td>
       <td>
       {{item.monnaie_rendu}}
       </td>
        </tr>
  
        
  
      </tbody>
    
    </table>
  
  
    
    <p align="center" *ngIf="type_details=='EAU_S'"><b>SOUSCRIPTION EAU</b>
    
    <br/>
  </p>
  
    <table *ngIf="type_details=='EAU_S'" class="table table-bordered table-striped mb-0">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom Personne</th>
        <th scope="col">Date Paiement </th>
        <th scope="col">Montant à Payer</th>
        <th scope="col">Montant Versé</th>
        <th scope="col">Monnaie</th>
        
      
      </tr>
    </thead>
    <tbody>
     <tr  *ngFor="let item of data_details" >
     <td>
     {{item.id_s_eau}}
     </td>
     <td>
     {{item.nom_pers}} {{item.prenom_pers}}
     </td>
     <td>
     {{item.date_s_eau}}
     </td>
     <td>
     {{item.montant_s_eau}}
     </td>
     <td>
     {{item.montant_verse}}
     </td>
     <td>
     {{item.monnaie_rendu}}
     </td>
      </tr>
  
      
  
    </tbody>
  
  </table>
  
  
  
  <p align="center" *ngIf="type_details=='FUNERAIRE'"><b>ASSISTANCE FUNERAIRE</b>
  <br/>
  
  </p>
  
  <table *ngIf="type_details=='FUNERAIRE'" class="table table-bordered table-striped mb-0">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Nom Famille</th>
      <th scope="col">Date Paiement </th>
      <th scope="col">Montant Payé</th>
      <th scope="col">Montant Versé</th>
      <th scope="col">Monnaie</th>
      
     
    </tr>
  </thead>
  <tbody>
   <tr *ngFor="let item of data_details">
   <td>
   {{item.id_ass_fun}}
   </td>
   <td>
   {{item.nom_famille}}
   </td>
   <td>
   {{item.date_pay_ass_fun}}
   </td>
   <td>
   {{item.montant_ass_fun}}
   </td>
   <td>
   {{item.montant_verse}}
   </td>
   <td>
   {{item.monnaie_rendue}}
   </td>
    </tr>
  
    
  
  </tbody>
  
  </table>
  
  
  <p align="center" *ngIf="type_details=='FONCIER'"><b>FONCIER</b>
  
  <br/>
  </p>
  <table *ngIf="type_details=='FONCIER'" class="table table-bordered table-striped mb-0">
  <thead>
    <tr>
      <th scope="col"> Doc</th>
      <th scope="col">Nom Personne</th>
      <th scope="col">Type Signature</th>
      <th scope="col">Date </th>
      <th scope="col"> Payé</th>
      <th scope="col"> Versé</th>
      <th scope="col">Monnaie</th>
      
      
    </tr>
  </thead>
  <tbody>
   <tr *ngFor="let item of data_details">
   <td>
   {{item.numero_sign_foncier}}
   </td>
   <td>
  
   {{item.nom_pers}}  {{item.prenom_pers}}
   </td>
   <td>
   {{item.type_sign_foncier}}
   </td>
   <td>
   {{item.date_sign_foncier}}
   </td>
   <td>
   {{item.montant_sign_foncier}}
   </td>
   <td>
   {{item.montant_verse}}
   </td>
   <td>
   {{item.monnaie_rendu}}
   </td>
  
    </tr>
  
    
  
  </tbody>
  
  </table>
    
  
  <p align="center" *ngIf="type_details=='SALLE'"><b>LOCATION SALLES</b>
  
  <br/>
  </p>
  <table *ngIf="type_details=='SALLE'" class="table table-bordered table-striped mb-0">
  <thead>
    <tr>
      <th scope="col"> Salle</th>
      <th scope="col">Nom Personne</th>
      <th scope="col">Details Location</th>
      <th scope="col">Periode </th>
      <th scope="col"> Payé</th>
      <th scope="col"> Versé</th>
      <th scope="col">Monnaie</th>
      
      
    </tr>
  </thead>
  <tbody>
   <tr  *ngFor="let item of data_details">
   <td>
   {{item.nom_salle}}
   </td>
   <td>
   {{item.nom_pers}} {{item.prenom_pers}}
   </td>
   <td>
   {{item.details_location}}
   </td>
   <td>
   {{item.debut_location}} - {{item.fin_location}}
   </td>
   <td>
   {{item.montant_location}}
   </td>
   <td>
   {{item.montant_verse}}
   </td>
   <td>
   {{item.monnaie_rendu}}
   </td>
    </tr>
  
    
  
  </tbody>
  
  </table>
    
  
    
    </div>
  
      </div>
     
  
     
      </div>
  
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
       
      </div>
      </div>
    `
  })
  export class VerifierModalV {
  @Input() public numero_mvt:any;
  test_data: any;
  libelle : any=''
  data_price : any=[];
  id_cat : any='';
  color1 : any=''
   nom_famille : any="";
   nom_repres_famille : any="";
   tel_repres_famille : any="";
   id_ut : any=0;
   base_url : any=Urlbase;
   data : any;
   data_mvt : any=[];
   type_details : any="";
   data_details : any=[];
   supprimer_recu:any=0;
    constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
    
  
    }
    ngOnInit(): void {
     
     // alert(this.numero_mvt);
     this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?numero_ticket='+this.numero_mvt+'&etat=1').subscribe(data=>{
    
      this.data_mvt=data;
   
      this.type_details=this.data_mvt[0]['type_mvt'];
      this.supprimer_recu=this.data_mvt[0]['etat'];
     
  
      if (this.type_details=="EAU_S")
      {
        let id=0;
        this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php?id_s_eau='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{
      
          this.data_details=data;
         // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
  
      }
      if (this.type_details=="FONCIER")
      {
  
      
        let id=0;
        this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php?id_sign_foncier='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{
  
          console.log(data)
      
          this.data_details=data;
         // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
  
  
      }
  
      if (this.type_details=="FUNERAIRE")
      {
  
        let id=0;
        this.http.get(this.base_url+'/apicaissevillage/funeraire/liste_paiement_funeraire.php?id_ass_fun='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{
  
          console.log(data)
      
          this.data_details=data;
         // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
  
      }
  
      if (this.type_details=="EAU_P")
      {
  
        let id=0;
        this.http.get(this.base_url+'/apicaissevillage/eau/liste_paiement.php?id_ab='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{
  
          console.log(data)
      
          this.data_details=data;
         // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
  
      }
  
      if (this.type_details=="SALLE")
      {
  
        this.http.get(this.base_url+'/apicaissevillage/salles/liste_location_salle.php?id_location='+this.data_mvt[0]['numero_mvt']).subscribe(data=>{
  
          console.log(data)
      
          this.data_details=data;
         // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
  
      }
  
    
      
     
     
     
  
  })
      
    }
  
  
   
  }
