import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Urlbase } from 'app/config/config';

@Component({
  selector: 'app-choix',
  templateUrl: './choix.component.html',
  styleUrls: ['./choix.component.scss']
})
export class ChoixComponent implements OnInit {
  nom_ut : any="";
  mdp_ut: any="";
  base_url : any=Urlbase
  data : any;
  data_choix : any=['CAISSE','TABLEAU DE BORD'];
  constructor( private router : Router,private http: HttpClient) { 
    


  }
  caisse()
  {
    this.router.navigate(['caisse'])
  }
  tbd()
  {
    this.router.navigate(['dashboard'])
  }
  deconnexion()
  {
    Swal.fire({
        title: 'Voulez vous vraiment vous deconnecter ?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          localStorage.clear()
        location.reload()
        } else if (result.isDenied) {
          
        }
      })  
  }
ngOnInit(): void {
    
}

}
