import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Urlbase } from 'app/config/config';
import Swal from 'sweetalert2';


@Component({
    selector: 'icons-cmp',
    moduleId: module.id,
    templateUrl: 'icons.component.html'
})

export class IconsComponent{
    data_personne: any=[];
    base_url : any=Urlbase
    base: any=Urlbase+'/villageapp/'
    data_mouvements : any=[];
    nom_mvt : any="";
    type_pers : any="";
    nom_pers : any="";
    data_temporaire : any=[];
    temoin_temp:any=0;
    data_two : any=[[]]
    a : any=0;
    b: any=0;
    index_tab:any=0;
    temoin_p1: any=0;
    temoin_bouton : any=-1;
    save : any=0;
    nbre_affiche:any=5;
    id_ut : any=0;
    data : any=[];
    constructor(private datePipe: DatePipe, private http : HttpClient,private modalService : NgbModal)
    {
        this.liste_personne();

    }
    cherch_pers()
    {
        if(this.type_pers=='')
        {
this.liste_personne();
        }
        else
        {
            this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne_admin.php?type_pers='+this.type_pers).subscribe(data=>{
  
               
                this.data_personne=data;
                this.data_temporaire=[];
     
     
     
                this.a=this.data_personne.length;
                this.b=this.a/this.nbre_affiche;
                
                let cp=0;
                let dernier_indice=0;
                this.data_two=[[]];
                if(this.a>this.nbre_affiche)
                {
                  this.temoin_bouton=0;
                  if(this.isInt(this.b))
                  {
                    this.temoin_p1=0;
                    
                    for(let i=0;i<=this.b-1;i++)
                    { 
                   
                      this.data_two.push([]);
                    
              
                      for(let j=0;j<=this.nbre_affiche-1;j++)
                      {
                       
                        this.data_two[i].push(this.data_personne[cp]);
                        cp=cp+1;
                        
                      }
              
              
                    }
                    console.log(this.data_two);
                  }
                  else
                  {
                    this.temoin_p1=this.temoin_p1+1
                    for(let i=0;i<Math.trunc(this.b);i++)
                    { 
                    
                      this.data_two.push([]);
              
                      for(let j=0;j<=this.nbre_affiche-1;j++)
                      {
                        
                        this.data_two[i].push(this.data_personne[cp]);
                        cp=cp+1;
                        dernier_indice=i;
                      }
              
              
                    }
              
                    for(let i=0;i<(this.a-(Math.trunc(this.b)*this.nbre_affiche));i++)
                    {
                      
                      
                      console.log(this.data_personne[cp])
                      this.data_two[dernier_indice+1].push(this.data_personne[cp])
                      cp++;
                    }
                    console.log(this.data_two);
                  }
              
                  this.data_temporaire=this.data_two[this.index_tab];
                }
                else
                {
                  this.temoin_bouton=-1
                  this.data_temporaire=data
                }
          
            
                //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
           
               
              
                
               
               
               
          
            })
        }
    }

    supprimer_pers(id_pers,nom_pers,prenom_pers)
    {
      Swal.fire({
        title: 'Voulez vous vraiment supprimer cette personne ' +nom_pers + ' '+prenom_pers+  '?',
        showDenyButton: true,
        text :' cela va le retirer de la liste des personnes mais son apparaitra dans lhistorique des operations déja effectuées , sauf que vous pourrrez plus effectuer dactions avec lui ',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let postData = new FormData();
            //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut');
            postData.append('id_ut' ,this.id_ut );
            
            postData.append('id_pers' , id_pers);
          
             

           
                
               
                this.http.post(this.base_url+'/apicaissevillage/personne/supprimer_personne.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Personne supprimée avec succès !',
                        showConfirmButton: true,
                        timer: 1500
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                       this.liste_personne();
                      })
                  
                  }
                  else
                  {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Erreur innatendue',
                       
                      })
                  }
                }
                  )

                
         
        } else if (result.isDenied) {
          
        }
      })
    }

    photo(img,nom_pers,id_pers)
    {
      const modalRef = this.modalService.open(PhotoMenu);
      modalRef.componentInstance.img=img
      modalRef.componentInstance.id_pers=id_pers;
      modalRef.componentInstance.nom_pers=nom_pers;
      
      modalRef.result.then(
          (result) => {
           this.liste_personne();
         
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
               this.liste_personne();
           
             
             }
          }
        );
    }

    modifier_personne(id_pers)
    {
      const modalRef = this.modalService.open(PersonneMenu);
      modalRef.componentInstance.id_pers=id_pers;
      modalRef.result.then(
          (result) => {
           this.liste_personne();
         
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
               this.liste_personne();
           
             
             }
          }
        );
    }
    modalpersonne()
    {
        const modalRef = this.modalService.open(PersonneMenu);
        modalRef.result.then(
            (result) => {
             this.liste_personne();
           
            
            },
            (reason) => {
               
               if(reason==undefined)
               {
                 this.liste_personne();
             
               
               }
            }
          );
        
    }
    recherche()
    {
        if(this.type_pers=='' && this.nom_pers=='')
        {

        }
        else
        {
            this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?nom_pers='+this.nom_pers+'&type_pers='+this.type_pers).subscribe(data=>{
  
                
                this.data_mouvements=data;

            
                //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
           
               
              
                
               
               
               
          
            })
        }
    }

    details(id,nom)
    {

      
      const modalRef = this.modalService.open(ModalMouvementP);
      modalRef.componentInstance.id_mvt=id;
      modalRef.componentInstance.nom_mvt=nom;
      modalRef.result.then(
          (result) => {
          this.liste_personne();
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_personne();
             
             }
          }
        );  
        this.nom_mvt=nom;
 
    }
    precedent()
    {
    
      this.index_tab--
   
      if(this.data_two[this.index_tab]=='' || this.data_two[this.index_tab]==undefined)
      {
        this.index_tab=this.save
       
       this.temoin_bouton=0;
      }
      else
      {
        this.save=this.index_tab;
       this.data_temporaire=this.data_two[this.index_tab];
      }
       
      
    }
    suivant()
    {

     this.index_tab++
     
     if(this.data_two[this.index_tab]=='' || this.data_two[this.index_tab]==undefined)
     {
     this.index_tab=this.save
      this.temoin_bouton=1;
     }
     else
     {
      this.save=this.index_tab;
      this.data_temporaire=this.data_two[this.index_tab];
     }
      
     
    
    
    }

     isInt(n) {
      return n % 1 === 0;
   }

    liste_personne()
  {
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne_admin.php').subscribe(data=>{
  
      this.data_personne=data;
      this.data_temporaire=[];
     
     
     
      this.a=this.data_personne.length;
      this.b=this.a/this.nbre_affiche;
      
      let cp=0;
      let dernier_indice=0;

      this.data_two=[[]];
      if(this.a>this.nbre_affiche)
      {
        this.temoin_bouton=0;
        if(this.isInt(this.b))
        {
          this.temoin_p1=0;
          
          for(let i=0;i<=this.b-1;i++)
          { 
         
            this.data_two.push([]);
          
    
            for(let j=0;j<=this.nbre_affiche-1;j++)
            {
             
              this.data_two[i].push(this.data_personne[cp]);
              cp=cp+1;
              
            }
    
    
          }
          console.log(this.data_two);
        }
        else
        {
          this.temoin_p1=this.temoin_p1+1
          for(let i=0;i<Math.trunc(this.b);i++)
          { 
          
            this.data_two.push([]);
    
            for(let j=0;j<=this.nbre_affiche;j++)
            {
              
              this.data_two[i].push(this.data_personne[cp]);
              cp=cp+1;
              dernier_indice=i;
            }
    
    
          }
    
          for(let i=0;i<(this.a-(Math.trunc(this.b)*this.nbre_affiche));i++)
          {
            
            
            console.log(this.data_personne[cp])
            this.data_two[dernier_indice+1].push(this.data_personne[cp])
            cp++;
          }
          console.log(this.data_two);
        }
    
        this.data_temporaire=this.data_two[this.index_tab];
      }
      else
      {
        this.temoin_bouton=-1
        this.data_temporaire=data
      }

      
     
   
      
    
     
     

  })
  }
}
@Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> PERSONNE </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
     
  
    
      <div class="col-6">
      Nom
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="nom_pers"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
  
      <br/>
  
      <div class="row">
     
     
  
    
      <div class="col-6">
     Prenom
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="prenom_pers"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
     
     
      <br/>
      <div class="row">
     
     
  
    
      <div class="col-6">
      Telephone
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="telephone_pers"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
     
     
      <br/>
      <div class="row">
      <div class="col-6">
      Type personne
      </div>
      <div class="col-6">
      <select class="form-control" [(ngModel)]="type_pers"  [ngModelOptions]="{standalone: true}"  >
     <option value=""></option>
     <option value="AU">AUTOCHTONE</option>
     <option value="AL">HALOGENE</option>
      </select>
      </div>
     
      </div>
     
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="valider()" >VALIDER</button>
      </div>
    `
  })
  export class PersonneMenu {
    @Input() public id_pers;
  test_data: any;
  libelle : any=''
  data_price : any=[];
  id_cat : any='';
  color1 : any=''
  nom_pers : any="";
  telephone_pers : any="";
  type_pers : any="";
  prenom_pers : any="";
  base_url : any=Urlbase;
  data : any;
    id_ut: any;
    data_pers : any=[];
    matricule_pers : any="";
    constructor(private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
  
    }
    ngOnInit(): void {
     
   if(this.id_pers==undefined)
   {

   }
   else
   {

 
      this.http.get(this.base_url+'/apicaissevillage/personne/affiche_personne.php?id_pers='+this.id_pers).subscribe(data=>{

          
          this.data_pers=data;
          this.nom_pers=this.data_pers[0]['nom_pers'];
          this.prenom_pers=this.data_pers[0]['prenom_pers'];
          this.telephone_pers=this.data_pers[0]['telephone_pers'];
          this.type_pers=this.data_pers[0]['type_pers'];
          this.matricule_pers=this.data_pers[0]['matricule_pers'];
          console.log(data);

      })
   }
      
      
    }
    getRandomString(nom,prenom,length) {
    
     var randomChars = '0123456789';
     var result = 'CI'+nom.charAt(0)+prenom.charAt(0)+'-';
     for ( var i = 0; i < (length/2); i++ ) {
         result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
     }
     var Radomstring='67851236545'
     for ( var i = 0; i < 3; i++ ) {
       result +=  Radomstring.charAt(Math.floor(Math.random() * Radomstring.length));
   }
     
     return result
 }
    valider()
    {
      
      if( this.nom_pers=='' || this.prenom_pers=='' || this.type_pers=='' || this.telephone_pers=="" )
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Champ(s) Vide(s)',
         
        })
      }
      else
  
      {

        if(this.id_pers==undefined)
        {
        //  alert(this.date_debut);
      
          
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
          postData.append('matricule_pers',this.getRandomString(this.nom_pers,this.prenom_pers,10));
          
            postData.append('nom_pers' , this.nom_pers);
            postData.append('prenom_pers' , this.prenom_pers);
            postData.append('type_pers' , this.type_pers);
            postData.append('telephone_pers' , this.telephone_pers);
            postData.append('id_ut' ,this.id_ut );
            
           
            this.http.post(this.base_url+'/apicaissevillage/personne/ajouter_personne.php', postData).subscribe(data=>{
              this.data=data;

              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Personne ajoutée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
            })
            // this.liste_cat()
            
             }
             else
             {
                 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         

            
              
        
        
        
        })
        
      
    
        }
        else
        {

          let postData = new FormData();
          //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut'); 
        
            postData.append('matricule_pers',this.matricule_pers);
              postData.append('nom_pers' , this.nom_pers);
              
              postData.append('prenom_pers' , this.prenom_pers);
              postData.append('type_pers' , this.type_pers);
              postData.append('telephone_pers' , this.telephone_pers);
              postData.append('id_ut' ,this.id_ut );
              postData.append('id_pers' ,this.id_pers  );
              
             
              this.http.post(this.base_url+'/apicaissevillage/personne/modifier_personne.php', postData).subscribe(data=>{
                this.data=data;
  
                if(this.data['success']==true)
                {
                   
           
               Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Personne modifiée avec succès !',
                showConfirmButton: true,
                timer: 1500
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                this.modalService.dismissAll()
           
              })
              // this.liste_cat()
              
               }
               else
               {
                   
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Erreur Inattendue',
                 
                })
                   
                   
         
               }
           
  
              
                
          
          
          
          })
        }
   
  
  
     
      }
  
    }
   
   
  }
  @Component({
    template: `
    <div class="modal-content" style="width:150% !important">
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> LISTE DES OPERATIONS DE  {{nom_mvt}} </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
      <table class=" table table-hover ">
      <thead class="thead-dark text-primary">
        <th>
          N°
        </th>
        <th>
          Date
        </th>
        <th>
         Libelle 
        </th>
        <th>
         Payé   
        </th>
        
         <th>
          Monnaie
          </th>
 
        <th align="center">
          Actions
        </th>
       
      </thead>
      <tbody >
        <tr *ngFor="let item of data_mouvements" >
         
          <td>
            {{item.numero_ticket}}
          </td>
          <td>
            {{item.date_mvt}}
          </td>
          <td>
            {{item.libelle_mvt}}
          </td>
          <td>
            {{item.montant_a_verse_mvt | number:'':''}}
          </td>
         
          <td>
            {{item.monnaie_rendue | number:'':''}}
          </td>
          <td>
            <button type="button" class="btn btn-primary" >
              <i class="bi bi-printer-fill" style="font-size: 1rem; color: white;"></i>
            </button>
          
          </td>
         
        </tr>

      </tbody>
    </table>
     
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
  
      </div>
      </div>
    `
  })
  export class ModalMouvementP {
    
    @Input() public id_mvt;
    @Input() public nom_mvt;
    
    base_url : any=Urlbase;
    data_mouvements : any=[];
    total_cotisation : any=0;
  
    constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
      
  
    }
    ngOnInit(){
    
      
this.details(this.id_mvt,this.nom_mvt)
      
    }

    details(id,nom)
    {
      this.nom_mvt=nom;
      this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?id_indiv='+id).subscribe(data=>{

          
          this.data_mouvements=data;
          console.log(data);
          this.total_cotisation=0;
         
          for(let i=0;i<this.data_mouvements.length;i++)
          {
             
              this.total_cotisation=parseInt(this.total_cotisation) + parseInt(this.data_mouvements[i]['montant_verse_mvt']);
          }
         
      
          //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
    }

  
   
  }


  @Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> PHOTO DE {{nom_pers}} </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
      <div align="center" >
      <img id="img" height="150"   src="{{base_url}}/apicaissevillage/personne/upload/{{photo_part}}" alt="..." />
      <br/><br/>
</div>
    
    <div class="row">

 <div class="col-12">
<input (change)="onFileChange($event)"    id="uploads" name="uploads" accept="image/*"   type="file" class="form-control" />
 </div>

</div>
  


      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="maj()" >Mis à Jour</button>
      </div>
    `
  })
  export class PhotoMenu {
    @Input() public img;
    @Input() public id_pers;
    @Input() public nom_pers;
    
  test_data: any;
  libelle : any=''
  data_price : any=[];
  id_cat : any='';
  color1 : any=''

  telephone_pers : any="";
  type_pers : any="";
  prenom_pers : any="";
  files:any=  [];
  base_url : any=Urlbase;
  base: any=Urlbase
  data : any;
    id_ut: any;
    photo_part : any="";
    constructor(private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
  
    }
    onFileChange(event)  {
     
    
        this.files.push(event.target.files[0]);
       
    
  }
    ngOnInit(): void {
     
    
      //this.get_info(this.img)
     this.photo_part=this.img;
      
    }
    get_info(img)
{
 
}
maj()
    {
      
         
    if(this.files.length==0)
    {
      //  this.toastr.error('Champ(s) vide(s)')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
     // this.spinner.show()
      let postData = new FormData();
      //alert(this.libelle)
      
        
       
        postData.append('id_pers' , this.id_pers);
   
       
        
        postData.append('file[]' , this.files[0]);
        //alert('fff');
       
        this.http.post(this.base_url+'/apicaissevillage/personne/req_ajout_image.php', postData).subscribe(data=>{
  
       
         if(data['success']==true)
         {
            
        //    this.spinner.hide()
        
        
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Photo Mise à jour avec succès! !',
          showConfirmButton: true,
          timer: 1500
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          this.modalService.dismissAll()
        })
       // this.liste_cat()
       
        }
        else
        {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erreur',
           
          })
         
        //    this.spinner.hide()
            
  
        }
    
    
    
    })
      
   
    }
      
    }
   
   
  }
