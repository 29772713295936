import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Urlbase } from 'app/config/config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  nom_ut : any="";
  mdp_ut: any="";
  base_url : any=Urlbase
  data : any;
  id_ut : any=0;
  constructor( private router : Router,private http: HttpClient) { 
    
    if(localStorage.getItem('id_ut')==undefined)
    {
      this.router.navigate(['connexion'])
    }
    else
    {
      this.id_ut=localStorage.getItem('id_ut')
      //dahb :
      if(localStorage.getItem('type_ut')=='ADMIN')
      {
        this.router.navigate(['dashboard'])
      }
      if(localStorage.getItem('type_ut')=='PATRIMOINE')
      {
        this.router.navigate(['dashboard'])
      }
      if(localStorage.getItem('type_ut')=='VERIFIEUR')
      {
        this.router.navigate(['verifieur'])

      }
      
      //caisse
      if(localStorage.getItem('type_ut')=='CAISSE')
      {
       
        this.router.navigate(['choix'])
        
      }
    }

  }
  
  valider()
  {
    if(this.nom_ut =='' || this.mdp_ut=='')
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else
    {
      //this.spinner.show()

      let postData = new FormData();
      postData.append('mdp_ut' , this.mdp_ut);
      postData.append('email_ut' , this.nom_ut);

     
      this.http.post(this.base_url+'/apicaissevillage/utilisateur/connexion.php',
      
      postData).subscribe(data=>{
        
      this.data=data;
      if(this.data['success']==true)
      {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Connecté!',
          showConfirmButton: true,
          timer: 1500
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          localStorage.setItem('id_ut', this.data['id_ut']);
          localStorage.setItem('nom_ut', this.data['nom_ut']);
          localStorage.setItem('prenom_ut', this.data['prenom_ut']);
          localStorage.setItem('type_ut', this.data['type_ut']);
      
          location.reload()
        })
        
     
       // this.spinner.hide();
      }
      else
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Utilisateur inconnu/désactivé',
         
        })
       
      //  this.spinner.hide();
      }
        
      
      },
      error  => {
      
      
      
      })
         
    }
  }
  ngOnInit(): void {
  }

}
