import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component,Input,OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Urlbase } from 'app/config/config';
import Swal from 'sweetalert2';

declare var google: any;

@Component({
    moduleId: module.id,
    selector: 'maps-cmp',
    templateUrl: 'maps.component.html'
})

export class MapsComponent implements OnInit {
    data_abonnements : any=[];
    base_url : any=Urlbase;
    nom_mvt : any="";
    data_mouvements : any=[];
    total : any=0;
    date_pay_ab : any="";
    mois_pay_ab:any="";
    anne_pay_ab : any=0;
    data_abn : any=[];
    total_verse: any=0;
    total_monnaie: any=0;
    id_ut :any=0;
    data : any=[];
    constructor(private datePipe : DatePipe,private http : HttpClient,private modalService : NgbModal)
    {
        this.liste_abonnes();

    }

    supprimer(id_s)
    {
  
      Swal.fire({
        title: 'Voulez vous vraiment supprimer cet abonnement  ?',
        showDenyButton: true,
        text :' cela retirer lutilisateur de labonnement mensuel du mois selectionné, il naura donc plus accès à leau pendant cette periode ',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let postData = new FormData();
            //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut');
            postData.append('type' ,'EAU_P' );
            
            postData.append('id_s_seau' , id_s);
          
             
  
           
                
               
                this.http.post(this.base_url+'/apicaissevillage/eau/supprimer_abonnement.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Abonnement supprimé avec succès !',
                        showConfirmButton: true,
                        timer: 1500
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                       this.liste_abonnes();
                      })
                  
                  }
                  else
                  {
  
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Erreur innatendue',
                       
                      })
                  }
                }
                  )
  
                
         
        } else if (result.isDenied) {
          
        }
      })
    }

    modal_abonnement()
    {
        const modalRef = this.modalService.open(AbonnEau);
        modalRef.result.then(
            (result) => {
            this.liste_abonnes();
            },
            (reason) => {
               
               if(reason==undefined)
               {
                this.liste_abonnes();
               
               }
            }
          );  
    }
    details(id,nom,type)
    {
      const modalRef = this.modalService.open(ModalMouvementA);
      modalRef.componentInstance.id_mvt=id;
      modalRef.componentInstance.nom_mvt=nom;
      modalRef.componentInstance.type_mvt=type;
      modalRef.result.then(
          (result) => {
          this.liste_abonnes();
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_abonnes();
             
             }
          }
        );  
        this.nom_mvt=nom;
    
    }

    rech_annee()
    {
        this.mois_pay_ab="";
        this.date_pay_ab="";

      
        if(this.anne_pay_ab==0)
        {
this.liste_abonnes();
        }
        else
        {
            
            let annee_mvt=this.anne_pay_ab
            this.http.get(this.base_url+'/apicaissevillage/eau/liste_paiement.php?&anne_pay_ab='+annee_mvt).subscribe(data=>{
      
                this.data_abonnements=data;
                this.total=0;
                this.total_verse=0;
                this.total_monnaie=0;
                for(let i=0;i<this.data_abonnements.length;i++)
                {
                    this.total=parseInt(this.total) + parseInt(this.data_abonnements[i]['montant_pay_ab']);
                    this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_abonnements[i]['monnaie_rendu']);
                    this.total_verse=parseInt(this.total_verse) + parseInt(this.data_abonnements[i]['montant_verse']);
                }
               
               
               
                
                
                
              
                
               
               
               
          
            })
        }
    }

    rech_mois()

    {
        this.anne_pay_ab=0;
        this.date_pay_ab="";
   
        
        if(this.mois_pay_ab=='')
        {
this.liste_abonnes();
        }
        else
        {
            let mois_mvt=this.datePipe.transform(this.mois_pay_ab,"MM");
            let annee_mvt=this.datePipe.transform(this.mois_pay_ab,"yyyy");
            this.http.get(this.base_url+'/apicaissevillage/eau/liste_paiement.php?mois_pay_ab='+mois_mvt+'&anne_pay_ab='+annee_mvt).subscribe(data=>{
                this.total=0;
                this.data_abonnements=data;
                this.total=0;
                this.total_verse=0;
                this.total_monnaie=0;
                for(let i=0;i<this.data_abonnements.length;i++)
                {
                    this.total=parseInt(this.total) + parseInt(this.data_abonnements[i]['montant_pay_ab']);
                    this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_abonnements[i]['monnaie_rendu']);
                    this.total_verse=parseInt(this.total_verse) + parseInt(this.data_abonnements[i]['montant_verse']);
                }
                
              
                
               
               
               
          
            })
        }
    }

    rech_date()
    {
       
        this.anne_pay_ab=0;
        this.mois_pay_ab="";
       

        if(this.date_pay_ab=='')
        {
this.liste_abonnes();
        }
        else
        {
            
            let date_mvt=this.datePipe.transform(this.date_pay_ab,"dd/MM/yyyy");
            
            this.http.get(this.base_url+'/apicaissevillage/eau/liste_paiement.php?date_pay_ab='+date_mvt).subscribe(data=>{
                this.total=0;
                this.data_abonnements=data;
                this.total=0;
                this.total_verse=0;
                this.total_monnaie=0;
                for(let i=0;i<this.data_abonnements.length;i++)
                {
                    this.total=parseInt(this.total) + parseInt(this.data_abonnements[i]['montant_pay_ab']);
                    this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_abonnements[i]['monnaie_rendu']);
                    this.total_verse=parseInt(this.total_verse) + parseInt(this.data_abonnements[i]['montant_verse']);
                }
                
                
              
                
               
               
               
          
            })
        }
   
   
    }

    liste_abonnes()
    {
        this.http.get(this.base_url+'/apicaissevillage/eau/liste_paiement.php').subscribe(data=>{
    
            this.data_abonnements=data;

            this.total=0;
            this.total=0;
            this.total_verse=0;
            this.total_monnaie=0;
            for(let i=0;i<this.data_abonnements.length;i++)
            {
                this.total=parseInt(this.total) + parseInt(this.data_abonnements[i]['montant_pay_ab']);
                this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_abonnements[i]['monnaie_rendu']);
                this.total_verse=parseInt(this.total_verse) + parseInt(this.data_abonnements[i]['montant_verse']);
            }
           
          
            
           
           
           
      
        })

    }
    ngOnInit() {
       
      
    }
}
@Component({
  template: `
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> SOUSCRIPTION EAU </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
   
    <div class="row">
   
   

  
    <div class="col-6">
    Nom Prenom
    </div>
    <div class="col-6">
  <select class="form-control" [(ngModel)]="id_pers" [ngModelOptions]="{standalone: true}" (change)="get_type_pers()">
  <option value="0"></option>
    <option *ngFor="let item of data_personne" value="{{item.id_pers}}">{{item.nom_pers}} {{item.prenom_pers}}</option>
  
  </select>
    </div>
    </div>

    <br/>

    <div class="row">
   
   

  
    <div class="col-6">
   Montant à Payer
    </div>
    <div class="col-6" >
    <input  type="number" [(ngModel)]="montant_s_eau" [ngModelOptions]="{standalone: true}" disabled class="form-control" />
  
    </div>
    </div>
   
   
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Montant Versé
    </div>
    <div class="col-6">
    <input  type="number" [(ngModel)]="montant_verse" (keyup)="calcul_monnaie($event)"  [ngModelOptions]="{standalone: true}" class="form-control" />
  
    </div>
    </div>
    <br/>
    <div class="row">
   
   

  
    <div class="col-6">
    Monnaie Rendue
    </div>
    <div class="col-6">
    <input  type="number" [(ngModel)]="monnaie_rendu" [ngModelOptions]="{standalone: true}" disabled class="form-control" />
  
    </div>
    </div>
   
   
   
   
    </div>
   
  
  
  
    
    
    
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajouter()" >AJOUTER</button>
    </div>
  `
})
export class AbonnEau {
  
test_data: any;
libelle : any=''
data_price : any=[];
id_cat : any='';
color1 : any=''
id_pers:any=0;
montant_s_eau : any=0;
montant_verse : any=0;
monnaie_rendu : any=0;
base_url : any=Urlbase;
data_personne: any=[];
data_config : any=[];
libelle_config : any="";
type_config : any="";
data_type_pers : any=[];
id_ut : any=0;
data : any;
  constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    this.liste_personne();

  }
  get_type_pers()
  {
   
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?id_pers='+this.id_pers).subscribe(data=>{
  
      this.data_type_pers=data;
      this.config(this.data_type_pers[0]["type_pers"])
      console.log(this.data_type_pers[0]["type_pers"])
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  config(type :string)
  {
    this.libelle_config="S_EAU"
    this.type_config="";
    this.http.get(this.base_url+'/apicaissevillage/config/liste_config.php?libelle_config='+this.libelle_config+"&type_config="+type).subscribe(data=>{
  
      this.data_config=data;
      console.log(data);
      this.montant_s_eau=this.data_config[0]['prix_config'];
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  
  calcul_monnaie(event : any)
  {
this.monnaie_rendu=this.montant_verse-this.montant_s_eau;
  }
  liste_personne()
  {
   // let type_pers="";
    let id=0;
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
  
      this.data_personne=data;
     // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }
  ajouter()
  {
    if( this.id_pers==0 || this.montant_s_eau==0 || this.montant_verse==0)
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
    //  alert(this.date_debut);
    
    if(this.montant_s_eau>this.montant_verse)
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Montant Versé < Montant à Verser',
       
      })
         
    }
    else
    {
      let postData = new FormData();
      //alert(this.libelle)
        this.id_ut=localStorage.getItem('id_ut'); 
       
          postData.append('id_pers' , this.id_pers);
          postData.append('montant_s_eau' , this.montant_s_eau);
          postData.append('montant_verse' , this.montant_verse);
          postData.append('monnaie_rendu' , this.monnaie_rendu);
          
          postData.append('id_ut' ,this.id_ut );
          
         
          this.http.post(this.base_url+'/apicaissevillage/eau/souscrire_eau.php', postData).subscribe(data=>{
            this.data=data;

            if(this.data['success']==true)
            {
               
       
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Famille ajoutée avec succès !',
            showConfirmButton: true,
            timer: 1500
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            this.modalService.dismissAll()
          })
          // this.liste_cat()
          
           }
           else
           {
             console.log(data)
               
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Erreur Inattendue',
             
            })
               
               
     
           }
       

          
            
      
      
      
      })
      
    }
        
   
      
    


   
    }
  }
  ngOnInit(): void {
   
  
    
    
  }
  typeoccup()
  {
    
  }

 
}
@Component({
  template: `
  <div class="modal-content" style="width:150% !important">
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> LISTE DES SOUSCRIPTIONS MENSUELS DE  {{nom_mvt}} </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
    <table class=" table table-hover table-dark">
    <thead class=" text-primary">
      <th>
        N°
      </th>
      <th>
        Date
      </th>
      <th>
       Libelle 
      </th>
      <th>
       Payé   
      </th>
      
       <th>
        Monnaie
        </th>

      <th align="center">
        Actions
      </th>
     
    </thead>
    <tbody >
      <tr *ngFor="let item of data_mouvements" >
       
        <td>
          {{item.numero_ticket}}
        </td>
        <td>
          {{item.date_mvt}}
        </td>
        <td>
          {{item.libelle_mvt}}
        </td>
        <td>
          {{item.montant_a_verse_mvt | number:'':''}}
        </td>
       
        <td>
          {{item.monnaie_rendue | number:'':''}}
        </td>
        <td>
          <button type="button" class="btn btn-primary" >
            <i class="bi bi-printer-fill" style="font-size: 1rem; color: white;"></i>
          </button>
        
        </td>
       
      </tr>

    </tbody>
  </table>
   
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>

    </div>
    </div>
  `
})
export class ModalMouvementA {
  
  @Input() public id_mvt;
  @Input() public nom_mvt;
  @Input() public type_mvt;
  
  base_url : any=Urlbase;
  data_mouvements : any=[];
  total_cotisation : any=0;
  id_ut : any=0;
  data : any=[];

  constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    

  }
  ngOnInit(){
  
    
this.details(this.id_mvt,this.nom_mvt,this.type_mvt)
    
  }

 


  details(id,nom,type_mvt)
  {
    this.nom_mvt=nom;
    this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?numero_mvt='+id+'&type_mvt='+type_mvt).subscribe(data=>{

        
        this.data_mouvements=data;
        console.log(data);
        this.total_cotisation=0;
       
        for(let i=0;i<this.data_mouvements.length;i++)
        {
           
            this.total_cotisation=parseInt(this.total_cotisation) + parseInt(this.data_mouvements[i]['montant_verse_mvt']);
        }
       
    
        //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
  }


 
}