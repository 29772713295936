import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Urlbase } from 'app/config/config';
import Swal from 'sweetalert2';
import { ModalMouvementF } from '../table/table.component';

@Component({
    selector: 'icons-cmp',
    moduleId: module.id,
    templateUrl: 'famille.component.html'
})

export class FamilleComponent{
    data_personne: any=[];
    base_url : any=Urlbase;
    data_mouvements : any=[];
    nom_mvt : any="";
    type_pers : any="";
    nom_famille : any="";
    nom_pers : any="";
    id_ut : any=0;
    data : any=[];
    data_famille : any= [];
    constructor(private datePipe: DatePipe, private http : HttpClient,private modalService : NgbModal)
    {
        this.liste_personne();
        this.liste_famille();

    }

    
    photo(img,nom_famille,id_famille)
    {
  
      const modalRef = this.modalService.open(PhotoMenu);
      modalRef.componentInstance.img=img
      modalRef.componentInstance.id_famille=id_famille;
      modalRef.componentInstance.nom_famille=nom_famille;
      
      modalRef.result.then(
          (result) => {
           this.liste_famille();
         
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
               this.liste_famille();
           
             
             }
          }
        );
    }
    supprimer_famille(id_famille,nom_famille)
    {
  
      Swal.fire({
        title: 'Voulez vous vraiment supprimer cette famille ' +nom_famille + ' ?',
        showDenyButton: true,
        text :' cela va le retirer de la liste des personnes mais son apparaitra dans lhistorique des operations déja effectuées , sauf que vous pourrrez plus effectuer dactions avec lui ',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let postData = new FormData();
            //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut');
            postData.append('id_ut' ,this.id_ut );
            
            postData.append('id_famille' , id_famille);
          
             

           
                
               
                this.http.post(this.base_url+'/apicaissevillage/famille/supprimer_famille.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Famille supprimée avec succès !',
                        showConfirmButton: true,
                        timer: 1500
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                       this.liste_personne();
                      })
                  
                  }
                  else
                  {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Erreur innatendue',
                       
                      })
                  }
                }
                  )

                
         
        } else if (result.isDenied) {
          
        }
      })
    }
modifier_famille(id_famille,nom_famille)
{
  
  const modalRef = this.modalService.open(FamilleMenu);
  modalRef.componentInstance.id_famille=id_famille;
  modalRef.componentInstance.nom_famille = nom_famille;
        
  modalRef.result.then(
      (result) => {
       this.liste_famille();
     
      
      },
      (reason) => {
         
         if(reason==undefined)
         {
           this.liste_famille();
       
         
         }
      }
    );
}
    liste_famille()
    {
      this.http.get(this.base_url+'/apicaissevillage/famille/liste_famille.php').subscribe(data=>{
    
        this.data_famille=data;
       
      
        
       
       
       
  
    })
    }
    modal_famille()
    {
        const modalRef = this.modalService.open(FamilleMenu);
        
        modalRef.result.then(
            (result) => {
             this.liste_famille();
           
            
            },
            (reason) => {
               
               if(reason==undefined)
               {
                 this.liste_famille();
             
               
               }
            }
          );
        
    }
    recherche()
    {
        if(this.type_pers=='' && this.nom_pers=='')
        {

        }
        else
        {
            this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?nom_pers='+this.nom_pers+'&type_pers='+this.type_pers).subscribe(data=>{
  
                
                this.data_mouvements=data;
            
                //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
           
               
              
                
               
               
               
          
            })
        }
    }

    details(id,nom)
    {

      const modalRef = this.modalService.open(ModalMouvementFam);
      modalRef.componentInstance.id_mvt=id;
      modalRef.componentInstance.nom_mvt=nom;
      modalRef.result.then(
          (result) => {
           this.liste_famille();
         
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
               this.liste_famille();
           
             
             }
          }
        );

        this.nom_mvt=nom;
     
    }

    liste_personne()
  {
    this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
  
      this.data_personne=data;
      
    
      
     
     
     

  })
  }
}
@Component({
  template: `
  <div class="modal-content" style="width:150% !important">
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> OPERATIONS ({{data_mouvements.length}})- {{nom_mvt}} </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
    <table class=" table table-hover table-dark">
    <thead class=" text-primary">
      <th>
        N°
      </th>
      <th>
        Date
      </th>
      <th>
       Libelle 
      </th>
      <th>
       Payé   
      </th>
      
       <th>
        Monnaie
        </th>

      <th align="center">
        Actions
      </th>
     
    </thead>
    <tbody >
      <tr *ngFor="let item of data_mouvements" >
       
        <td>
          {{item.numero_ticket}}
        </td>
        <td>
          {{item.date_mvt}}
        </td>
        <td>
          {{item.libelle_mvt}}
        </td>
        <td>
          {{item.montant_a_verse_mvt | number:'':''}}
        </td>
       
        <td>
          {{item.monnaie_rendue | number:'':''}}
        </td>
        <td>
          <button type="button" class="btn btn-primary" >
            <i class="bi bi-printer-fill" style="font-size: 1rem; color: white;"></i>
          </button>
        
        </td>
       
      </tr>

    </tbody>
  </table>
   
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>

    </div>
    </div>
  `
})
export class ModalMouvementFam {
  
  @Input() public id_mvt;
  @Input() public nom_mvt;
  
  base_url : any=Urlbase;
  data_mouvements : any=[];
  total_cotisation : any=0;

  constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    

  }
  ngOnInit(){
  
    
this.details(this.id_mvt,this.nom_mvt)
    
  }

  details(id,nom)

  {
   
    this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?id_indiv='+id).subscribe(data=>{
  
      console.log(data)
      this.data_mouvements=data;
  
      //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
 
     
    
      
     
     
     

  })
  }


 
}
@Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> FAMILLE </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
     
  
    
      <div class="col-6">
      Nom Famille
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="nom_famille"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
  
      <br/>
  
      <div class="row">
     
     
  
    
      <div class="col-6">
     Nom Representant Famille
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="nom_repres_famille"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
     
     
      <br/>
      <div class="row">
     
     
  
    
      <div class="col-6">
      Telephone Representant Famille
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="tel_repres_famille"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
     
     
     
     
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="ajouter()" >AJOUTER</button>
      </div>
    `
  })
  export class FamilleMenu {
    @Input() public id_famille;
   
  test_data: any;
  libelle : any=''
  data_price : any=[];
  id_cat : any='';
  color1 : any=''
   nom_famille : any="";
   nom_repres_famille : any="";
   tel_repres_famille : any="";
   id_ut : any=0;
   base_url : any=Urlbase;
   data : any;
    constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
  
    }
    ngOnInit(): void {
     

      if (this.id_famille==undefined)
      {

      }
      else
      {

        this.http.get(this.base_url+'/apicaissevillage/famille/affiche_famille.php?id_famille='+this.id_famille).subscribe(data=>{

          
          this.data=data;
          this.nom_famille=this.data[0]['nom_famille'];
          this.nom_repres_famille=this.data[0]['nom_repres_famille'];
          this.tel_repres_famille=this.data[0]['tel_repres_famille'];
        
          console.log(data);

      })

      }
    
      
      
    }
    ajouter()
    {
      if(this.id_famille==undefined)
      {
        if( this.nom_famille=='' || this.nom_repres_famille=='' || this.tel_repres_famille=='' )
        {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Champ(s) Vide(s)',
           
          })
        }
        else
    
        {
        //  alert(this.date_debut);
        
            
            let postData = new FormData();
            //alert(this.libelle)
              this.id_ut=localStorage.getItem('id_ut'); 
             
                postData.append('nom_famille' , this.nom_famille);
                postData.append('nom_repres_famille' , this.nom_repres_famille);
                postData.append('tel_repres_famille' , this.tel_repres_famille);
                
                postData.append('id_ut' ,this.id_ut );
                
               
                this.http.post(this.base_url+'/apicaissevillage/famille/ajouter_famille.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                     
             
                 Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Famille ajoutée avec succès !',
                  showConfirmButton: true,
                  timer: 1500
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  this.modalService.dismissAll()
                })
                // this.liste_cat()
                
                 }
                 else
                 {
                     
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erreur Inattendue',
                   
                  })
                     
                     
           
                 }
             
    
                
                  
            
            
            
            })
            
          
        
    
    
       
        }
      }
      else
      {
        if( this.nom_famille=='' || this.nom_repres_famille=='' || this.tel_repres_famille=='' )
        {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Champ(s) Vide(s)',
           
          })
        }
        else
    
        {
        //  alert(this.date_debut);
        
            
            let postData = new FormData();
            //alert(this.libelle)
              this.id_ut=localStorage.getItem('id_ut'); 
             
                postData.append('nom_famille' , this.nom_famille);
                postData.append('nom_repres_famille' , this.nom_repres_famille);
                postData.append('tel_repres_famille' , this.tel_repres_famille);
                postData.append('id_famille' , this.id_famille);
                
                postData.append('id_ut' ,this.id_ut );
                
               
                this.http.post(this.base_url+'/apicaissevillage/famille/modifier_famille.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                     
             
                 Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Famille Modifiée avec succès !',
                  showConfirmButton: true,
                  timer: 1500
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  this.modalService.dismissAll()
                })
                // this.liste_cat()
                
                 }
                 else
                 {
                     
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erreur Inattendue',
                   
                  })
                     
                     
           
                 }
             
    
                
                  
            
            
            
            })
            
          
        
    
    
       
        }
      }
   
      
    }
  
   
  }
  @Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> PHOTO DE {{nom_pers}} </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
      <div align="center" >
      <img id="img" height="150"   src="{{base_url}}/apicaissevillage/famille/upload/{{photo_part}}" alt="..." />
      <br/><br/>
</div>
    
    <div class="row">

 <div class="col-12">
<input (change)="onFileChange($event)"    id="uploads" name="uploads" accept="image/*"   type="file" class="form-control" />
 </div>

</div>
  


      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="maj()" >Mis à Jour</button>
      </div>
    `
  })
  export class PhotoMenu {
    @Input() public img;
    @Input() public id_famille;
    @Input() public nom_famille;
    
  test_data: any;
  libelle : any=''
  data_price : any=[];
  id_cat : any='';
  color1 : any=''

  telephone_pers : any="";
  type_pers : any="";
  prenom_pers : any="";
  files:any=  [];
  base_url : any=Urlbase;
  base: any=Urlbase
  data : any;
    id_ut: any;
    photo_part : any="";
    constructor(private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
  
    }
    onFileChange(event)  {
     
    
        this.files.push(event.target.files[0]);
       
    
  }
    ngOnInit(): void {
     
    
      //this.get_info(this.img)
     this.photo_part=this.img;
      
    }
    get_info(img)
{
 
}
maj()
    {
      
         
    if(this.files.length==0)
    {
      //  this.toastr.error('Champ(s) vide(s)')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s)',
       
      })
    }
    else

    {
     // this.spinner.show()
      let postData = new FormData();
      //alert(this.libelle)
      
        
       
        postData.append('id_famille' , this.id_famille);
   
       
        
        postData.append('file[]' , this.files[0]);
        //alert('fff');
       
        this.http.post(this.base_url+'/apicaissevillage/famille/req_ajout_image.php', postData).subscribe(data=>{
  
       
         if(data['success']==true)
         {
            
        //    this.spinner.hide()
        
        
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Photo Mise à jour avec succès! !',
          showConfirmButton: true,
          timer: 1500
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          this.modalService.dismissAll()
        })
       // this.liste_cat()
       
        }
        else
        {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erreur',
           
          })
         
        //    this.spinner.hide()
            
  
        }
    
    
    
    })
      
   
    }
      
    }
   
   
  }