import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Urlbase } from 'app/config/config';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare interface TableData {
    headerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'table-cmp',
    moduleId: module.id,
    templateUrl: 'table.component.html'
})

export class TableComponent implements OnInit{
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;  
    data_abonnements : any=[];
    base_url : any=Urlbase
    nom_mvt : any="";
    data_mouvements : any=[];
    total_cotisation : any=0;
    total : any=0;
    date_sign_foncier : any="";
    mois_sign_foncier:any="";
    annee_sign_foncier : any=0;
    data_abn : any=[];
    data_foncier : any=[];
    data_deces : any=[];
    type_sign_foncier : any="";
    total_monnaie : any=0;
    total_verse : any=0;
    constructor(private datePipe : DatePipe,private http : HttpClient,private modalService : NgbModal)
    {
        this.liste_foncier();
        
        
     

    }
 
    imprimer()
    {
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);  
      const wb: XLSX.WorkBook = XLSX.utils.book_new();  
      XLSX.utils.book_append_sheet(wb, ws, 'FONCIERS');  
      XLSX.writeFile(wb, 'Fonciers_'+this.annee_sign_foncier+'_'+this.mois_sign_foncier+'.xlsx'); 
    }

    modal_abonnement()
    {
        const modalRef = this.modalService.open(PaiementFoncier);
        modalRef.result.then(
            (result) => {
            this.liste_foncier();
            },
            (reason) => {
               
               if(reason==undefined)
               {
                this.liste_foncier();
               
               }
            }
          );  
    }
    details(id,nom,type)
    {

      const modalRef = this.modalService.open(ModalMouvementF);
      modalRef.componentInstance.id_mvt=id;
      modalRef.componentInstance.nom_mvt=nom;
      modalRef.componentInstance.type_mvt=type;
      modalRef.result.then(
          (result) => {
          this.liste_foncier();
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_foncier();
             
             }
          }
        );  

     
    }

    rech_type()
    {
      
      this.mois_sign_foncier="";
      this.date_sign_foncier="";
      

    
      if(this.type_sign_foncier=='')
      {
this.liste_foncier();
      }
      else
      {
          
          let type=this.type_sign_foncier
          this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php?&type_sign_foncier='+type).subscribe(data=>{
    
              this.data_foncier=data;
            
               
              this.total=0;
              this.total_monnaie=0;
              this.total_verse =0;
              for(let i=0;i<this.data_foncier.length;i++)
              {
                  this.total=parseInt(this.total) + parseInt(this.data_foncier[i]['montant_sign_foncier']);
                  this.total_verse=parseInt(this.total_verse) + parseInt(this.data_foncier[i]['montant_verse']);
                  this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_foncier[i]['monnaie_rendu']);
              }
            
             
              
              
            
              
             
             
             
        
          })
      }

    }

    rech_annee()
    {
        this.mois_sign_foncier="";
        this.date_sign_foncier="";

      
        if(this.annee_sign_foncier==0)
        {
this.liste_foncier();
        }
        else
        {
            
            let annee_mvt=this.annee_sign_foncier
            this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php?&annee_sign_foncier='+annee_mvt).subscribe(data=>{
      
                this.data_foncier=data;
              
               
                this.total=0;
                this.total_monnaie=0;
                this.total_verse =0;
                for(let i=0;i<this.data_foncier.length;i++)
                {
                    this.total=parseInt(this.total) + parseInt(this.data_foncier[i]['montant_sign_foncier']);
                    this.total_verse=parseInt(this.total_verse) + parseInt(this.data_foncier[i]['montant_verse']);
                    this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_foncier[i]['monnaie_rendu']);
                }
              
               
                
                
              
                
               
               
               
          
            })
        }
    }

    rech_mois()

    {
        this.annee_sign_foncier=0;
        this.date_sign_foncier="";
   
        
        if(this.mois_sign_foncier=='')
        {
this.liste_foncier();
        }
        else
        {
            let mois_mvt=this.datePipe.transform(this.mois_sign_foncier,"MM");
            let annee_mvt=this.datePipe.transform(this.mois_sign_foncier,"yyyy");
            this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php?mois_sign_foncier='+mois_mvt+'&annee_sign_foncier='+annee_mvt).subscribe(data=>{
                this.total=0;
                this.data_foncier=data;
              
             
                this.total=0;
                this.total_monnaie=0;
                this.total_verse =0;
                for(let i=0;i<this.data_foncier.length;i++)
                {
                    this.total=parseInt(this.total) + parseInt(this.data_foncier[i]['montant_sign_foncier']);
                    this.total_verse=parseInt(this.total_verse) + parseInt(this.data_foncier[i]['montant_verse']);
                    this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_foncier[i]['monnaie_rendu']);
                }
              
                
               
               
               
          
            })
        }
    }

    rech_date()
    {
       
        this.annee_sign_foncier=0;
        this.mois_sign_foncier="";
       

        if(this.date_sign_foncier=='')
        {
this.liste_foncier();
        }
        else
        {
            
            let date_mvt=this.datePipe.transform(this.date_sign_foncier,"dd/MM/yyyy");
            
            this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php?date_sign_foncier='+date_mvt).subscribe(data=>{
                this.total=0;
                this.data_foncier=data;
                this.total=0;
              this.total_monnaie=0;
              this.total_verse =0;
              for(let i=0;i<this.data_foncier.length;i++)
              {
                  this.total=parseInt(this.total) + parseInt(this.data_foncier[i]['montant_sign_foncier']);
                  this.total_verse=parseInt(this.total_verse) + parseInt(this.data_foncier[i]['montant_verse']);
                  this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_foncier[i]['monnaie_rendu']);
              }
             
                
              
                
               
               
               
          
            })
        }
   
   
    }

    liste_foncier()
    {
        this.http.get(this.base_url+'/apicaissevillage/foncier/liste_paiement_foncier.php').subscribe(data=>{
    
            this.data_foncier=data;

            this.total=0;
            this.total_monnaie=0;
            this.total_verse =0;
            for(let i=0;i<this.data_foncier.length;i++)
            {
                this.total=parseInt(this.total) + parseInt(this.data_foncier[i]['montant_sign_foncier']);
                this.total_verse=parseInt(this.total_verse) + parseInt(this.data_foncier[i]['montant_verse']);
                this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_foncier[i]['monnaie_rendu']);
            }

          //  this.total=this.data_foncier.length;
          
           
          
            
           
           
           
      
        })

    }
    ngOnInit() {
       
      
    }
}
@Component({
    template: `
    
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> PAIEMENT FONCIER </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
     
  
    
      <div class="col-6">
      Personne
      </div>
      <div class="col-6">
  
      <select class="form-control" id="selUser"  [(ngModel)]="id_pers" [ngModelOptions]="{standalone: true}" >
      <option data-tokens="0" value="0"></option>
        <option *ngFor="let item of data_personne" value="{{item.id_pers}}"  >{{item.nom_pers}} {{item.prenom_pers}}</option>
      
      </select>
    
      </div>
      </div>
      <br/>
      <div class="row">
      <div class="col-6">
      Numero Document
      </div>
      <div class="col-6">
      <input  type="text" [(ngModel)]="numero_sign_foncier" class="form-control" />
      </div>
     
      </div>
      <br/>
      <div class="row">
      <div class="col-4">
      Type 
      </div>
      <div class="col-8">
     <select class="form-control" [(ngModel)]="type_foncier" (change)="calcul()">
     <option value="">--</option>
     <option value="AV">ATTESTATION VILLAGEOISE</option>
   
     <option value="AL">AUTORISATION LOTISSEMENT</option>
     </select>
      </div>
     
      </div>
      <br/>
      <div class="row">
      <div class="col-6">
      Superficie Terrain en M2
      </div>
      <div class="col-6">
      <input  type="number"  [(ngModel)]="superf_t" (keyup)="calcul_superf($event)" class="form-control" />
      </div>
     
      </div>
     
      <br/>
      <div class="row">
      <div class="col-6">
      Montant 
      </div>
      <div class="col-6">
      <input disabled [(ngModel)]="montant_a_verse" type="number" class="form-control" />
      </div>
     
      </div>
      <br/>
      <div class="row">
      <div class="col-6">
      Montant Verse
      </div>
      <div class="col-6">
      <input   type="number" [(ngModel)]="montant_verse" (keyup)="calcul_monnaie($event)"  class="form-control" />
      </div>
      </div>
      <br/>
      <div class="row">
      <div class="col-6">
      Monnaie à Rendre
      </div>
      <div class="col-6">
      <input   type="number" [(ngModel)]="monnaie_rendu" disabled class="form-control" />
      </div>
      </div>
     
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="payer()" >Payer</button>
      </div>
    `
  })
  export class PaiementFoncier {
    
  test_data: any;
  libelle : any=''
  data_price : any=[];
  superf_t : any=0;
  id_cat : any='';
  color1 : any=''
  data_personne : any=[];
  montant_a_verse : any=0;
  monnaie_rendu : any=0;
  montant_verse : any=0;
  type_foncier : any="";
  numero_sign_foncier:any='';
  base_url : any=Urlbase
  id_pers : any=0;
  id_ut : any=0;
  data : any=[];
    constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
      this.liste_personne();
  
    }
    payer()
    {
      if( this.id_pers==0 || this.numero_sign_foncier==0 || this.type_foncier=="" || this.superf_t==0 || this.montant_a_verse==0 || this.montant_verse==0 )
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Champ(s) Vide(s)',
         
        })
      }
      else
  
      {
      //  alert(this.date_debut);
      
          
          let postData = new FormData();
          //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut'); 
           
              postData.append('type_sign_foncier' , this.type_foncier);
              postData.append('numero_sign_foncier' , this.numero_sign_foncier);
              postData.append('id_pers' , this.id_pers);
              postData.append('montant_sign_foncier' , this.montant_a_verse);
              postData.append('id_ut' ,this.id_ut );
              postData.append('montant_verse' ,this.montant_verse );
              postData.append('monnaie_rendu' ,this.monnaie_rendu );
              postData.append('super_sign_foncier' ,this.superf_t );
              
             
              this.http.post(this.base_url+'/apicaissevillage/foncier/paiement_foncier.php', postData).subscribe(data=>{
                this.data=data;
  
                if(this.data['success']==true)
                {
                   
           
               Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Foncier ajouté avec succès !',
                showConfirmButton: true,
                timer: 1500
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                this.modalService.dismissAll()
              })
              // this.liste_cat()
              
               }
               else
               {
                  console.log(data); 
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Erreur Inattendue',
                 
                })
                   
                   
         
               }
           
  
              
                
          
          
          
          })
          
        
      
  
  
     
      }
    }
    calcul()
    {
      if(this.type_foncier=='AL')
      {
        this.montant_a_verse=1000000
      }
    }
    
    calcul_monnaie(event: any)
    {
  this.monnaie_rendu=this.montant_verse-this.montant_a_verse;
    }
    calcul_superf(event: any)
  
    {
      if(this.type_foncier=='AL')
      {
       
      }
      else
      {
        if(this.superf_t>1500)
        {
          this.montant_a_verse=350* this.superf_t;
    
        }
        else
        {
          this.montant_a_verse=500000;
        }
    
      }
     
    }
    liste_personne()
    {
     // let type_pers="";
      let id=0;
      this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
    
        this.data_personne=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
    }
    ngOnInit(): void {
     
    
      
      
    }
    typeoccup()
    {
      
    }
  
   
  }

  @Component({
    template: `
    <div class="modal-content" style="width:150% !important">
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> RECU DE  {{nom_mvt}} </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
      <table class=" table table-hover table-dark">
      <thead class=" text-primary">
        <th>
          N°
        </th>
        <th>
          Date
        </th>
        <th>
         Libelle 
        </th>
        <th>
         Payé   
        </th>
        
         <th>
          Monnaie
          </th>
 
        <th align="center">
          Actions
        </th>
       
      </thead>
      <tbody >
        <tr *ngFor="let item of data_mouvements" >
         
          <td>
            {{item.numero_ticket}}
          </td>
          <td>
            {{item.date_mvt}}
          </td>
          <td>
            {{item.libelle_mvt}}
          </td>
          <td>
            {{item.montant_a_verse_mvt | number:'':''}}
          </td>
         
          <td>
            {{item.monnaie_rendue | number:'':''}}
          </td>
          <td>
            <button type="button" class="btn btn-primary" >
              <i class="bi bi-printer-fill" style="font-size: 1rem; color: white;"></i>
            </button>
          
          </td>
         
        </tr>

      </tbody>
    </table>
     
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
  
      </div>
      </div>
    `
  })
  export class ModalMouvementF {
    
    @Input() public id_mvt;
    @Input() public nom_mvt;
    @Input() public type_mvt;
    
    base_url : any=Urlbase;
    data_mouvements : any=[];
    total_cotisation : any=0;
  
    constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
      
  
    }
    ngOnInit(){
    
      
this.details(this.id_mvt,this.nom_mvt,this.type_mvt)
      
    }

    details(id,nom,type)
    {
      this.nom_mvt=nom;
      this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?numero_mvt='+id+'&type_mvt='+type).subscribe(data=>{

          
          this.data_mouvements=data;
          console.log(data);
          this.total_cotisation=0;
         
          for(let i=0;i<this.data_mouvements.length;i++)
          {
             
              this.total_cotisation=parseInt(this.total_cotisation) + parseInt(this.data_mouvements[i]['montant_verse_mvt']);
          }
         
      
          //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
     
         
        
          
         
         
         
    
      })
    }

  
   
  }