import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule } from '@angular/common/http';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { DatePipe } from "@angular/common";
import { PersonneMenu } from "./pages/icons/icons.component";
import {  FamilleMenu, ModalMouvementFam } from "./pages/famille/famille.component";
import {   CarteSouscription, SouscriptionMenu } from "./pages/souscription/souscription.component";
import { searchFilterPipe } from "./pipes/search/search.pipe";
import { AbonnEau } from "./pages/maps/maps.component";
import { ModalMouvementF, PaiementFoncier } from "./pages/table/table.component";
import { MomentModule } from "angular2-moment";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { LoginComponent } from "./pages/login/login.component";
import { AbonnEauC, CaisseComponent, DecesAjouterC, FamilleMenuC, modalTicket, PaiementEauC, PaiementFoncierC, PaiementFuneraireC, PaiementSallesC, PersonneMenuC, VerifierModalC } from "./pages/caisse/caisse.component";

import { QRCodeModule } from 'angular2-qrcode';
import { NgxPrintModule } from "ngx-print";
import { ChoixComponent } from "./pages/choix/choix.component";
import { VerifierModalV, VerifieurComponent } from "./pages/verifieur/verifieur.component";
@NgModule({
  declarations: [
    ChoixComponent,
    AppComponent,
    VerifieurComponent,
    VerifierModalV,
    FamilleMenu,
    AbonnEau,
    AbonnEauC,
    CarteSouscription,
    LoginComponent,
    CaisseComponent,
    modalTicket,
    FamilleMenuC,
    ModalMouvementF,
    PaiementEauC,
    PersonneMenuC,
    PaiementFuneraireC,
    PaiementSallesC,
    PaiementFoncierC,
    CaisseComponent,
    DecesAjouterC,
    LoginComponent,
    VerifierModalC,
    AdminLayoutComponent,
    PersonneMenu,
    SouscriptionMenu,
    PaiementFoncier
    
    
  ],
  imports: [
    BrowserAnimationsModule,
    QRCodeModule,
   
    NgxPrintModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    NavbarModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    FooterModule,
    HttpClientModule,
    FixedPluginModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
