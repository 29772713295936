import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Urlbase } from 'app/config/config';
import Swal from 'sweetalert2';

@Component({
    selector: 'icons-cmp',
    moduleId: module.id,
    templateUrl: 'souscription.component.html'
})

export class SouscriptionComponent{
    data_s_eaau: any=[];
    base_url : any=Urlbase
    data_mouvements : any=[];
    nom_mvt : any="";
    type_pers : any="";
    nom_pers : any="";
    data_famille : any= [];
    date_s_eau : any="";
    annee_s_eau : any=0;
    mois_s_eau : any="";
    total : any=0;
    total_verse : any=0;
    total_monnaie : any=0;
    id_ut : any=0;
    data : any=[];
    constructor(private datePipe: DatePipe, private http : HttpClient,private modalService : NgbModal)
    {
        this.liste_s_eau();
        this.liste_famille();

    }

    carte(id_s_eau,id_pers)
    {
      const modalRef = this.modalService.open(CarteSouscription);
      modalRef.componentInstance.id_s_eau=id_s_eau;
      modalRef.componentInstance.id_pers=id_pers;
    

      modalRef.result.then(
          (result) => {
           this.liste_s_eau();
         
          
          },
          (reason) => {
             
             if(reason==undefined)
             {
               this.liste_s_eau();
           
             
             }
          }
        );
    }

    supprimer(id_s)
    {
  
      Swal.fire({
        title: 'Voulez vous vraiment supprimer cette souscription  ?',
        showDenyButton: true,
        text :' cela va la retirer de la liste des souscriptions , sauf que vous pourrrez plus effectuer dabonnement mensuel avec la personne concernée ',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let postData = new FormData();
            //alert(this.libelle)
            this.id_ut=localStorage.getItem('id_ut');
            postData.append('type' ,'EAU_S' );
            
            postData.append('id_s_seau' , id_s);
          
             

           
                
               
                this.http.post(this.base_url+'/apicaissevillage/eau/supprimer_souscripteur.php', postData).subscribe(data=>{
                  this.data=data;
    
                  if(this.data['success']==true)
                  {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Souscription supprimée avec succès !',
                        showConfirmButton: true,
                        timer: 1500
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                       this.liste_s_eau();
                      })
                  
                  }
                  else
                  {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Erreur innatendue',
                       
                      })
                  }
                }
                  )

                
         
        } else if (result.isDenied) {
          
        }
      })
    }

    liste_famille()
    {
      this.http.get(this.base_url+'/apicaissevillage/famille/liste_famille.php').subscribe(data=>{
    
        this.data_famille=data;
       
      
        
       
       
       
  
    })
    }
    modal_souscription()
    {
        const modalRef = this.modalService.open(SouscriptionMenu);
        modalRef.result.then(
            (result) => {
             this.liste_s_eau();
           
            
            },
            (reason) => {
               
               if(reason==undefined)
               {
                 this.liste_s_eau();
             
               
               }
            }
          );
        
    }
    recherche()
    {
        if(this.type_pers=='' && this.nom_pers=='')
        {

        }
        else
        {
            this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?nom_pers='+this.nom_pers+'&type_pers='+this.type_pers).subscribe(data=>{
  
                
                this.data_mouvements=data;
            
                //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
           
               
              
                
               
               
               
          
            })
        }
    }

    details(id,nom,type_mvt)
    {
      const modalRef = this.modalService.open(ModalMouvementS);
      modalRef.componentInstance.id_mvt=id;
      modalRef.componentInstance.nom_mvt=nom;
      modalRef.componentInstance.type_mvt=type_mvt;
      
      modalRef.result.then(
          (result) => {
          this.liste_s_eau();
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_s_eau();
             
             }
          }
        );  
        this.nom_mvt=nom;
  
    }

    rech_annee()
    {
        this.mois_s_eau="";
        this.date_s_eau="";

      
        if(this.annee_s_eau==0)
        {
this.liste_s_eau();
        }
        else
        {
            
            let annee_mvt=this.annee_s_eau
            this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php?&annee_s_eau='+annee_mvt).subscribe(data=>{
      
                this.data_s_eaau=data;
                this.total=0;
                this.total=0;
                this.total_verse=0;
                this.total_monnaie=0;
                for(let i=0;i<this.data_s_eaau.length;i++)
                {
                  this.total=parseInt(this.total) + parseInt(this.data_s_eaau[i]['montant_s_eau']);
                  this.total_verse=parseInt(this.total_verse) + parseInt(this.data_s_eaau[i]['montant_verse']);
                  this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_s_eaau[i]['monnaie_rendu']);
                }
                
                
              
                
               
               
               
          
            })
        }
    }

    rech_mois()

    {
        this.annee_s_eau=0;
        this.date_s_eau="";
   
        
        if(this.mois_s_eau=='')
        {
this.liste_s_eau();
        }
        else
        {
            let mois_mvt=this.datePipe.transform(this.mois_s_eau,"MM");
            let annee_mvt=this.datePipe.transform(this.mois_s_eau,"yyyy");
            this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php?mois_s_eau='+mois_mvt+'&annee_s_eau='+annee_mvt).subscribe(data=>{
                this.total=0;
                this.data_s_eaau=data;
                this.total=0;
                this.total_verse=0;
                this.total_monnaie=0;
                for(let i=0;i<this.data_s_eaau.length;i++)
                {
                  this.total=parseInt(this.total) + parseInt(this.data_s_eaau[i]['montant_s_eau']);
                  this.total_verse=parseInt(this.total_verse) + parseInt(this.data_s_eaau[i]['montant_verse']);
                  this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_s_eaau[i]['monnaie_rendu']);
                }
              
                
               
               
               
          
            })
        }
    }

    rech_date()
    {
       
        this.annee_s_eau=0;
        this.mois_s_eau="";
       

        if(this.date_s_eau=='')
        {
this.liste_s_eau();
        }
        else
        {
            let date_mvt=this.datePipe.transform(this.date_s_eau,"dd/MM/yyyy");
            this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php?date_s_eau='+date_mvt).subscribe(data=>{
                this.total=0;
                this.data_s_eaau=data;
                this.total=0;
                this.total_verse=0;
                this.total_monnaie=0;
                for(let i=0;i<this.data_s_eaau.length;i++)
                {
                  this.total=parseInt(this.total) + parseInt(this.data_s_eaau[i]['montant_s_eau']);
                  this.total_verse=parseInt(this.total_verse) + parseInt(this.data_s_eaau[i]['montant_verse']);
                  this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_s_eaau[i]['monnaie_rendu']);
                }
                
              
                
               
               
               
          
            })
        }
   
   
    }

    liste_s_eau()
  {
    this.http.get(this.base_url+'/apicaissevillage/eau/liste_souscripteurs.php').subscribe(data=>{
  
      this.data_s_eaau=data;
      this.total=0;
      this.total_verse=0;
      this.total_monnaie=0;
      for(let i=0;i<this.data_s_eaau.length;i++)
      {
        this.total=parseInt(this.total) + parseInt(this.data_s_eaau[i]['montant_s_eau']);
        this.total_verse=parseInt(this.total_verse) + parseInt(this.data_s_eaau[i]['montant_verse']);
        this.total_monnaie=parseInt(this.total_monnaie) + parseInt(this.data_s_eaau[i]['monnaie_rendu']);
      }
      
      
    
      
     
     
     

  })
  }
}
@Component({
  template: `
  <div class="modal-content" style="width:150% !important">
    <div  class="modal-header" >
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i> RECU DE  {{nom_mvt}} </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
   
    <table class=" table table-hover table-dark">
    <thead class=" text-primary">
      <th>
        N°
      </th>
      <th>
        Date
      </th>
      <th>
       Libelle 
      </th>
      <th>
       Payé   
      </th>
      
       <th>
        Monnaie
        </th>

      <th align="center">
        Actions
      </th>
     
    </thead>
    <tbody >
      <tr *ngFor="let item of data_mouvements" >
       
        <td>
          {{item.numero_ticket}}
        </td>
        <td>
          {{item.date_mvt}}
        </td>
        <td>
          {{item.libelle_mvt}}
        </td>
        <td>
          {{item.montant_a_verse_mvt | number:'':''}}
        </td>
       
        <td>
          {{item.monnaie_rendue | number:'':''}}
        </td>
        <td>
          <button type="button" class="btn btn-primary" >
            <i class="bi bi-printer-fill" style="font-size: 1rem; color: white;"></i>
          </button>
        
        </td>
       
      </tr>

    </tbody>
  </table>
   
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>

    </div>
    </div>
  `
})
export class ModalMouvementS {
  
  @Input() public id_mvt;
  @Input() public nom_mvt;
  @Input() public type_mvt;
  
  base_url : any=Urlbase;
  data_mouvements : any=[];
  total_cotisation : any=0;

  constructor(private http : HttpClient ,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
 
   //console.log(this.id_cats)
    //this.get_price(this.id_cat)
    

  }
  ngOnInit(){
  
    
this.details(this.id_mvt,this.nom_mvt,this.type_mvt)
    
  }

  details(id,nom,type_mvt)
  {
    this.nom_mvt=nom;
    this.http.get(this.base_url+'/apicaissevillage/utilisateur/liste_mouvements.php?numero_mvt='+id+'&type_mvt='+type_mvt).subscribe(data=>{

        
        this.data_mouvements=data;
        console.log(data);
        this.total_cotisation=0;
       
        for(let i=0;i<this.data_mouvements.length;i++)
        {
           
            this.total_cotisation=parseInt(this.total_cotisation) + parseInt(this.data_mouvements[i]['montant_verse_mvt']);
        }
       
    
        //this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
  }


 
}
@Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> SOUSCRIPTION EAU </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     
     
      <div class="row">
     
     
  
    
      <div class="col-6">
      Nom Prenom
      </div>
      <div class="col-6">
    <select class="form-control" [(ngModel)]="id_pers" [ngModelOptions]="{standalone: true}" (change)="get_type_pers()">
    <option value="0"></option>
      <option *ngFor="let item of data_personne" value="{{item.id_pers}}">{{item.nom_pers}} {{item.prenom_pers}}</option>
    
    </select>
      </div>
      </div>
  
      <br/>
  
      <div class="row">
     
     
  
    
      <div class="col-6">
     Montant à Payer
      </div>
      <div class="col-6" >
      <input  type="number" [(ngModel)]="montant_s_eau" [ngModelOptions]="{standalone: true}" disabled class="form-control" />
    
      </div>
      </div>
     
     
      <br/>
      <div class="row">
     
     
  
    
      <div class="col-6">
      Montant Versé
      </div>
      <div class="col-6">
      <input  type="number" [(ngModel)]="montant_verse" (keyup)="calcul_monnaie($event)"  [ngModelOptions]="{standalone: true}" class="form-control" />
    
      </div>
      </div>
      <br/>
      <div class="row">
     
     
  
    
      <div class="col-6">
      Monnaie Rendue
      </div>
      <div class="col-6">
      <input  type="number" [(ngModel)]="monnaie_rendu" [ngModelOptions]="{standalone: true}" disabled class="form-control" />
    
      </div>
      </div>
     
     
     
     
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="ajouter()" >AJOUTER</button>
      </div>
    `
  })
  export class SouscriptionMenu {
    
  test_data: any;
  libelle : any=''
  data_price : any=[];
  id_cat : any='';
  color1 : any=''
  id_pers:any=0;
  montant_s_eau : any=0;
  montant_verse : any=0;
  monnaie_rendu : any=0;
  base_url : any=Urlbase;
  data_personne: any=[];
  data_config : any=[];
  libelle_config : any="";
  type_config : any="";
  data_type_pers : any=[];
  id_ut : any=0;
  data : any;
    constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
      this.liste_personne();
  
    }
    get_type_pers()
    {
     
      this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php?id_pers='+this.id_pers).subscribe(data=>{
    
        this.data_type_pers=data;
        this.config(this.data_type_pers[0]["type_pers"])
        console.log(this.data_type_pers[0]["type_pers"])
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
    }
    config(type :string)
    {
      this.libelle_config="S_EAU"
      this.type_config="";
      this.http.get(this.base_url+'/apicaissevillage/config/liste_config.php?libelle_config='+this.libelle_config+"&type_config="+type).subscribe(data=>{
    
        this.data_config=data;
        console.log(data);
        this.montant_s_eau=this.data_config[0]['prix_config'];
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
    }
    
    calcul_monnaie(event : any)
    {
  this.monnaie_rendu=this.montant_verse-this.montant_s_eau;
    }
    liste_personne()
    {
     // let type_pers="";
      let id=0;
      this.http.get(this.base_url+'/apicaissevillage/personne/liste_personne.php').subscribe(data=>{
    
        this.data_personne=data;
       // this.data_menu =[{'id_choix':1,'nom_menu':'PERSONNE ('+this.data_personne.length+')','icon':'../assets/eau.png'},{'id_choix':2,'nom_menu':'FAMILLE ('+this.data_famille.length+')'},{'id_choix':3,'nom_menu':'ABONNEMENT EAU'}]
   
       
      
        
       
       
       
  
    })
    }
    ajouter()
    {
      if( this.id_pers==0 || this.montant_s_eau==0 || this.montant_verse==0)
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Champ(s) Vide(s)',
         
        })
      }
      else
  
      {
      //  alert(this.date_debut);
      
      if(this.montant_s_eau>this.montant_verse)
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Montant Versé < Montant à Verser',
         
        })
           
      }
      else
      {
        let postData = new FormData();
        //alert(this.libelle)
          this.id_ut=localStorage.getItem('id_ut'); 
         
            postData.append('id_pers' , this.id_pers);
            postData.append('montant_s_eau' , this.montant_s_eau);
            postData.append('montant_verse' , this.montant_verse);
            postData.append('monnaie_rendu' , this.monnaie_rendu);
            
            postData.append('id_ut' ,this.id_ut );
            
           
            this.http.post(this.base_url+'/apicaissevillage/eau/souscrire_eau.php', postData).subscribe(data=>{
              this.data=data;
  
              if(this.data['success']==true)
              {
                 
         
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Famille ajoutée avec succès !',
              showConfirmButton: true,
              timer: 1500
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              this.modalService.dismissAll()
            })
            // this.liste_cat()
            
             }
             else
             {
               console.log(data)
                 
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erreur Inattendue',
               
              })
                 
                 
       
             }
         
  
            
              
        
        
        
        })
        
      }
          
     
        
      
  
  
     
      }
    }
    ngOnInit(): void {
     
    
      
      
    }
    typeoccup()
    {
      
    }
  
   
  }

  @Component({
    template: `
      <div  class="modal-header" >
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i> CARTE D'ABONNE EAU </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="wrapper" >
      <div align="center" >

      <b>CARTE D'ABONNE-EAU : {{this.data_s[0]['matricule_pers']}}</b>

      </div>
      <table width="100%" >
      <tr>

      <td rowspan="4">
      
      <img  height="90px" src="{{base}}/assets/img/{{this.data_s[0]['profil_pers']}}" />
      </td>
     


      </tr>
      <tr>
      <td colspan="4">
      <p><b>Nom : </b> {{this.data_s[0]['nom_pers']}}</p> 
      <p><b>Prenom : </b> {{this.data_s[0]['prenom_pers']}}</p>
    
      <p><b>Telephone :</b> {{this.data_s[0]['telephone_pers']}}</p>
       <p><b>Date Souscription :</b> {{this.data_s[0]['date_s_eau']}}</p>
      
      </td>
  
      <td>
      <br/>
      <br/>
      <br/>
      <qr-code [value]="qrInfo"></qr-code>
      <br/>
      <br/>
      <br/>
      </td>

      </tr>
     
     

      </table>
     
   
     
      </div>
     
    
    
    
      
      
      
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="imprimer()" >IMPRIMER</button>
      </div>
    `
  })
  export class CarteSouscription {
    @Input() public id_pers;
    @Input() public id_s_eau;
  test_data: any;
  libelle : any=''
  base: any=Urlbase
  data_price : any=[];
  id_cat : any='';
  color1 : any=''

  montant_s_eau : any=0;
  montant_verse : any=0;
  monnaie_rendu : any=0;
  base_url : any=Urlbase;
  data_personne: any=[];
  data_config : any=[];
  libelle_config : any="";
  type_config : any="";
  data_type_pers : any=[];
  id_ut : any=0;
  data : any;
  data_s : any=[];
  item : any=[];
  qrInfo : any;
    constructor( private http : HttpClient,private modalService:  NgbModal, public activeModal: NgbActiveModal) {
   
     //console.log(this.id_cats)
      //this.get_price(this.id_cat)
   
  
    }
    
    imprimer()
    {
      let printContents, popupWin;
      printContents = document.getElementById('wrapper').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print tab</title>
            <style>
            body { 
              max-width: 500px; 
              max-height: 1500px; 
              margin:0 auto; 
              text-align:center; 
              color:#000; 
              font-family: Arial, Helvetica, sans-serif; 
              font-size:12px; 
            
          }
          #wrapper { 
              min-width: 250px; 
              margin: 0px auto; 
          }
          #wrapper img { 
              max-width: 300px; 
              width: auto; 
          }
      
          h2, h3, p { 
              margin: 5px 0;
          }
          .left { 
              width:100%; 
              float:right; 
              text-align:right; 
              margin-bottom: 3px;
              margin-top: 3px;
          }
          .right { 
              width:40%; 
              float:right; 
              text-align:right; 
              margin-bottom: 3px; 
          }
          .table, .totals { 
              width: 100%; 
              margin:10px 0; 
          }
          .table th { 
              border-top: 1px solid #000; 
              border-bottom: 1px solid #000; 
              padding-top: 4px;
              padding-bottom: 4px;
          }
          .table td { 
              padding:0; 
          }
          .totals td { 
              width: 24%; 
              padding:0; 
          }
          .table td:nth-child(2) { 
              overflow:hidden; 
          }
      
          @media print {
              body { text-transform: uppercase; }
              #buttons { display: none; }
              #wrapper { width: 100%; margin: 0; font-size:9px; }
              #wrapper img { max-width:300px; width: 80%; }
              #bkpos_wrp{
                  display: none;
              }
          }
      
            </style>
          </head>
      <body style="receipt" onload="window.print();window.close()">${printContents}</body>
        </html>`
      );
      popupWin.document.close();  
    }

    ngOnInit(): void {

      
     
    
      this.http.get(this.base_url+'/apicaissevillage/eau/afficher_carte.php?id_s_eau='+this.id_s_eau).subscribe(data=>{
    
        this.data_s=data;

        this.item = [{
          'ID PERS': this.data_s[0]['id_pers'],
          'DATE ': this.data_s[0]['date_s_eau']
          
        }]
        this.qrInfo = JSON.stringify(this.item);
       
      
        
       
       
       
  
    })
      
    }
  
   
  }
